import { IPerson } from '../../interfaces/models';
import { Action } from '@ngrx/store';

export const SET = '[Persons] Set';
export const ADD = '[Persons] Add';
export const EDIT = '[Persons] Edit';
export const DELETE = '[Persons] Delete';
export const OPEN_MODAL = '[Persons] Open modal';
export const CLOSE_MODAL = '[Persons] Close modal';

export class Set implements Action {
  readonly type = SET;

  constructor(public data: IPerson[]) {}
}

export class Add implements Action {
  readonly type = ADD;

  constructor(public data: IPerson) {}
}

export class Edit implements Action {
  readonly type = EDIT;

  constructor(public data: IPerson) {}
}

export class Delete implements Action {
  readonly type = DELETE;

  constructor(public id: any) {}
}

export class OpenModal implements Action {
  readonly type = OPEN_MODAL;
}

export class CloseModal implements Action {
  readonly type = CLOSE_MODAL;
}

export type All = Set | Add | Edit | Delete | OpenModal | CloseModal;
