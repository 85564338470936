import { IProduit } from '../../interfaces/models';
import { Action } from '@ngrx/store';

export const SET = '[Produits] Set';
export const ADD = '[Produits] Add';
export const EDIT = '[Produits] Edit';
export const DELETE = '[Produits] Delete';
export const OPEN_MODAL = '[Produits] Open modal';
export const CLOSE_MODAL = '[Produits] Close modal';

export class Set implements Action {
  readonly type = SET;

  constructor(public data: IProduit[]) {}
}

export class Add implements Action {
  readonly type = ADD;

  constructor(public data: IProduit) {}
}

export class Edit implements Action {
  readonly type = EDIT;

  constructor(public data: IProduit) {}
}

export class Delete implements Action {
  readonly type = DELETE;

  constructor(public id: any) {}
}

export class OpenModal implements Action {
  readonly type = OPEN_MODAL;
}

export class CloseModal implements Action {
  readonly type = CLOSE_MODAL;
}

export type All = Set | Add | Edit | Delete | OpenModal | CloseModal;
