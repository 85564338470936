import { IDepot } from './../../interfaces/models';
import * as DepotsActions from '../actions/depots.actions';

export type Action = DepotsActions.All;

// Reducer function
export function depotsReducer(data: IDepot[] = [], action: Action) {
  switch (action.type) {
    case DepotsActions.SET: {
      return action.data;
    }
    case DepotsActions.EDIT: {
      data.forEach((depot: IDepot, index) => {
        if (depot.id === action.data.id) {
          data[index] = action.data;
        }
      });

      return [...data];
    }
    case DepotsActions.ADD: {
      return [action.data, ...data];
    }
    case DepotsActions.DELETE: {
      const newData = data.filter((depot: IDepot) => {
        return depot.id !== action.id;
      });

      return newData;
    }
    case DepotsActions.OPEN_MODAL: {
      return true;
    }
    case DepotsActions.CLOSE_MODAL: {
      return false;
    }
    default: {
      return data;
    }
  }
}
