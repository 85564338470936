import { DepotService } from './depot.service';
import { UserService } from './../user/user.service';
import { IOption } from './../../../ui/interfaces/option';
import { IDepot, TypeDepot } from './../../../interfaces/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Content } from 'src/app/ui/interfaces/modal';
import * as DepotActions from '../../../store/actions/depots.actions';

@Component({
  selector: 'app-depot',
  templateUrl: './depot.component.html',
  styleUrls: ['./depot.component.scss']
})
export class DepotComponent extends BasePageComponent implements OnInit, OnDestroy {

  depots: IDepot[];
  depotForm: FormGroup;
  type: IOption[];
  loading = false;
  change: boolean;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private userS: UserService,
    private depotService: DepotService
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: 'Dépot',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Jaugeage',
          route: 'default-dashboard'
        },
        {
          title: 'Dépot'
        }
      ]
    };
    this.type = [
      {
        label: 'Réel',
        value: TypeDepot.reel
      },
      {
        label: 'Fictif',
        value: TypeDepot.fictif
      }
    ];
    this.depots = [];
    this.change = false;
    this.initData();
  }

  initData() {
    const data: IDepot = { id: null};
    this.depots.push(data);
  }

  ngOnInit() {
    super.ngOnInit();

    this.store.select('depots').subscribe(datas => {
      if (datas && datas.length) {
        if (!this.depots[0].id) {
          this.depots.pop();
        }
        this.depots = datas;
        !this.pageData.loaded ? this.setLoaded() : null;
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.initDepotForm(row);
    this.modal.open({
      body,
      header,
      footer,
      options: null
    });
  }

  // init form
  initDepotForm(data: IDepot) {
    if (!data) {
      this.depotForm = this.fb.group({
        nom: ['', Validators.required],
        localite: ['', Validators.required]
      });
    } else {
      this.depotForm = this.fb.group({
        id: [data.id],
        nom: [data.nom, Validators.required],
        localite: [data.localite, Validators.required]
      });
    }

     // detect form changes
    this.depotForm.valueChanges.subscribe(() => {
      this.change = true;
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
    if (this.depotForm) {
      this.depotForm.reset();
    }
  }

  addDepot(form: FormGroup) {
    const newData: IDepot = form.value;
    this.loading = true;
    this.depotService.create(newData)
      .subscribe(
        (data) => {
          if (data) {
            this.loading = false;
            this.messageSuccess('Création');
            this.store.dispatch(new DepotActions.Add(data));
          }
        },
        (error) => {
          this.loading = false;
          this.messageError();
        }
      );

    this.closeModal();
  }

  getAccess(operation: string) {
    return this.userS.verifieRole(operation);
  }

  onUpdate(form: FormGroup) {
    this.loading = true;
    if (form.valid) {
      const res = form.value;
      this.depotService.updateDepot(res)
      .subscribe(
        (data) => {
          this.loading = false;
          this.store.dispatch(new DepotActions.Edit(data));
          this.messageSuccess('Modification');
        },
        (error) => {
          this.loading = false;
          this.messageError();
          console.log('error ', error);
        }
      );
      this.closeModal();
    }
  }

  onRemove() {
    this.closeModal();
    if (this.idDelete) {
      this.remove(this.idDelete);
      this.idDelete = null;
    }
  }

  remove(id: any) {
    this.loading = true;
    this.depotService.supprimer(id)
      .subscribe(
        () => {
          this.loading = false;
          this.messageSuccess('Suppression');
          this.store.dispatch(new DepotActions.Delete(id));
        },
        (error) => {
          this.loading = false;
          this.messageError();
        }
      );
  }

}
