import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { Content } from 'src/app/ui/interfaces/modal';

@Component({
  selector: 'app-retour-bon-transfert',
  templateUrl: './retour-bon-transfert.component.html',
  styleUrls: ['./retour-bon-transfert.component.scss']
})
export class RetourBonTransfertComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  change: boolean;
  loading = false;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: 'Retour bon transfert',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'retour-bon-transfert'
        }
      ]
    };
    this.change = false;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.modal.open({
      body,
      header,
      footer,
      options : { height: 'auto', width: 600, closeButton: false, overlay: true, overlayClose: true }
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
  }
}
