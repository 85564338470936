import { ILivraison, Niveau, IPerson } from './../../../interfaces/models';
import { LivraisonService } from './../livraison/livraison.service';
import { IOption } from './../../../ui/interfaces/option';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { Content } from 'src/app/ui/interfaces/modal';
import { CommonService } from 'src/app/services/common/common.service';
import { JaugeageService } from 'src/app/layout/vertical/jaugeage.service';
import * as SjtActions from './../../../store/actions/stocks.actions';
import * as LivraisonActions from './../../../store/actions/livraisons.actions';
import { IDepot } from 'src/app/interfaces/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transfert-depot',
  templateUrl: './transfert-depot.component.html',
  styleUrls: ['./transfert-depot.component.scss']
})
export class TransfertDepotComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  change: boolean;
  loading = false;
  proprietaires: IOption[];
  clients: IPerson[];
  reservoirs: IOption[];
  depots: IOption[];
  depotExpediteurs: IOption[];
  reservoir: IOption;
  depot: IDepot;
  litrage15 = 0;
  tmv = 0;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private comS: CommonService,
    private fb: FormBuilder,
    private jaugeS: JaugeageService,
    private sortieS: LivraisonService,
    private router: Router
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: 'Bordereau de transfert',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'bon-transfert'
        }
      ]
    };
    this.change = false;
    this.proprietaires = [];
    this.clients = [];
    this.reservoirs = [];
    this.depot = {};
    this.reservoir = {label: null, value: null};
    this.depots = [];
    this.depotExpediteurs = [];
  }

  ngOnInit() {
    super.ngOnInit();
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    const depotId =  userInfo.depots_id.id;
    const role = userInfo.niveau;
    this.depot = userInfo.depots_id;
    this.store.select('persons').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(person => {
          if (person.type === 'PROPRIETAIRE') {
            const c: IOption = {label: '', value: ''};
            c.label = person.nom.toUpperCase();
            c.value = person.id;
            this.proprietaires.push(c);
          }

          if (person.type === 'CLIENT') {
            this.clients.push(person);
          }
        });
      }
    });

    this.store.select('depots').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(depot => {
          const c: IOption = {label: '', value: ''};
          c.label = depot.nom.toUpperCase();
          c.value = depot.id;
          this.depots.push(c);
        });
      }
    });

    this.store.select('depots').subscribe(datas => {
      if (datas && datas.length) {
        if (role === Niveau.superAdministrateur || role === Niveau.admin) {
          datas.forEach(de => {
            const c: IOption = {label: '', value: ''};
            c.label = de.nom.toUpperCase();
            c.value = de.id;
            this.depotExpediteurs.push(c);
          });
        } else {
          const c: IOption = {label: '', value: ''};
          c.label = this.depot.nom.toUpperCase();
          c.value = this.depot.id;
          this.depotExpediteurs.push(c);
        }
      }
    });

    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(contenant => {
          if (userInfo.niveau === 'ADMIN_DEPOT' || userInfo.niveau === 'SUPER_ADMIN' ) {
            const c: IOption = {label: '', value: ''};
            c.label = contenant.code.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
            c.value = contenant.id;
            this.reservoirs.push(c);
          } else {
            if (contenant.depots_id.id === depotId && contenant.type === 'RESERVOIR') {
              const c: IOption = {label: '', value: ''};
              c.label = contenant.code.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
              c.value = contenant.id;
              this.reservoirs.push(c);
              this.reservoir = this.reservoirs[0];
            }
          }
        });
      }
    });
    this.initForm();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.modal.open({
      body,
      header,
      footer,
      options : { height: 'auto', width: 600, closeButton: false, overlay: true, overlayClose: true }
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
  }

  // init form
  initForm() {
    this.pageForm = this.fb.group({
    numero: [2000000],
    numero_bl: [0, Validators.required],
    date: [new Date(), Validators.required],
    heure: [this.comS.hourString(new Date()), Validators.required],
    depot_expediteur: [this.depotExpediteurs[0].value, Validators.required],
    depot_destinateur: [this.depots[0].value, Validators.required],
    proprietaire: ['', Validators.required],
    client: [this.clients[0].nom, Validators.required],
    tank: [this.reservoir.value, Validators.required],
    index_avant: [0, Validators.required],
    index_apres: [0, Validators.required],
    tm: [27, Validators.required],
    d15: [0, Validators.required],
    la: [0, Validators.required],
    l15: [this.litrage15],
    tmv: [this.tmv],
    coef: [0],
    observation: [''],
    type: ['BT']
    });

    // detect form changes
    this.pageForm.valueChanges.subscribe((formValue: ILivraison) => {
      this.change = true;
      if (formValue.d15 && formValue.tm) {
        formValue.coef = this.jaugeS.coef(formValue.d15, formValue.tm);
        this.litrage15 = Math.round((formValue.coef * formValue.la));
        this.tmv = Math.round(((this.litrage15 * (formValue.d15 / 1000)) / 1000) * 1000) / 1000;
      }
    });
  }

  saveData(form: FormGroup) {
    if (form.valid) {
      const newData = form.value;
      newData.date = newData.date;
      newData.coef = this.jaugeS.coef(newData.d15, newData.tm);
      const client = this.clients.find( d => d.nom === newData.client);
      newData.client = client.id;

      this.loading = false;
      this.sortieS.create(newData)
      .subscribe(
        (data) => {
          if (data) {
            this.loading = false;
            this.pageForm.reset();
            this.messageSuccess('Création');
            this.store.dispatch(new LivraisonActions.Add(data));
            this.jaugeS.getData(this.jaugeS.url + '?ctrl=stock', 'stocks', 'setDataStore', SjtActions);
            setTimeout(() => {
              this.router.navigateByUrl('/vertical/historique-transfert-depot');
            }, 4300);
          }
        },
        (error) => {
          this.loading = false;
          this.messageError();
        }
      );
    }
  }

}
