import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

    constructor() { }

    dateString(date: Date) {
      if (!date) {
        date = new Date();
      }
      const annee = date.getFullYear();
      let mois: any = date.getMonth() + 1;
      switch (mois) {
        case 1: mois = '01'; break;
        case 2: mois = '02'; break;
        case 3: mois = '03'; break;
        case 4: mois = '04'; break;
        case 5: mois = '05'; break;
        case 6: mois = '06'; break;
        case 7: mois = '07'; break;
        case 8: mois = '08'; break;
        case 9: mois = '09'; break;
      }
      let jour: any = date.getDate();

      switch (jour) {
        case 1: jour = '01'; break;
        case 2: jour = '02'; break;
        case 3: jour = '03'; break;
        case 4: jour = '04'; break;
        case 5: jour = '05'; break;
        case 6: jour = '06'; break;
        case 7: jour = '07'; break;
        case 8: jour = '08'; break;
        case 9: jour = '09'; break;
      }
      return  annee + '-' + mois + '-' + jour;
    }

    hourString(date: Date) {
      return date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    }

    stringDate(date: string) {
      return new Date(date);
    }
    getObject(datas: any[], value: any) {}

    compareDate(dateDebut: any, dateFin: any, date: any) {
      date = new Date(date);
      dateDebut = new Date(dateDebut);
      dateFin = new Date(dateFin);

      return (date >= dateDebut && date <= dateFin);
    }

    getYear(date: string) {

    }

    setDate(date: any) {
      let newdate: number;

      if (date.length === 9) {
        date = date.replace('-', '0');
        date = date.replace('-', '00');
        newdate = +date;
      }

      date = date.replace('-', '0');
      date = date.replace('-', '0');
      newdate = +date;

      return newdate;
    }

}
