import { Router } from '@angular/router';
import { IJaugeage } from './../../../interfaces/models';
import { CommonService } from './../../../services/common/common.service';
import { IOption } from './../../../ui/interfaces/option';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { Content } from 'src/app/ui/interfaces/modal';
import { JaugeageService } from 'src/app/layout/vertical/jaugeage.service';
import * as SjtActions from './../../../store/actions/stocks.actions';
import * as JaugeagesActions from './../../../store/actions/jaugeages.actions';

@Component({
  selector: 'app-jaugeage-tank',
  templateUrl: './jaugeage-tank.component.html',
  styleUrls: ['./jaugeage-tank.component.scss']
})
export class JaugeageTankComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  loading = false;
  change: boolean;
  types: IOption[];
  reservoirs: IOption[];
  reservoir: any = '';
  jaugeages: IJaugeage[];
  heureJauge = '08:00:00';
  
  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private comS: CommonService,
    private jaugeS: JaugeageService,
    private router: Router
  ) {
    super(store, httpSv, modal);
    this.pageData = {
      title: 'Enregistrer un jaugeage',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'Jaugeage'
        }
      ]
    };
    this.types = [
      {
        label: 'stockage',
        value: 'STOCKAGE'
      },
      {
        label: 'fermeture',
        value: 'FERMETURE'
      }
    ];
    this.change = false;
    this.reservoirs = [];
    this.jaugeages = [];
   }

  ngOnInit() {
    super.ngOnInit();
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    const depotId =  userInfo.depots_id.id;
    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(contenant => {
          if (userInfo.niveau === 'ADMIN_DEPOT' || userInfo.niveau === 'SUPER_ADMIN' ) {
            const c: IOption = {label: '', value: ''};
            c.label = contenant.code.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
            c.value = contenant.id;
            this.reservoirs.push(c);
          } else {
            if (contenant.depots_id.id === depotId && contenant.type === 'RESERVOIR') {
              const c: IOption = {label: '', value: ''};
              c.label = contenant.code.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
              c.value = contenant.id;
              this.reservoirs.push(c);
              this.reservoir = this.reservoirs[0].value;
            }
          }
        });
      }
    });

    this.store.select('jaugeages').subscribe(datas => {
      if (datas && datas.length) {
        this.jaugeages = datas;
      }
    });
    this.initForm();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.initForm();
    this.modal.open({
      body,
      header,
      footer,
      options: { height: 'auto', width: 500, closeButton: false, overlay: true, overlayClose: true }
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
    if (this.pageForm) {
      this.pageForm.reset();
    }
  }

   // init form
   initForm() {
    this.pageForm = this.fb.group({
      date: [new Date(), Validators.required],
      heure: [this.heureJauge, Validators.required],
      reservoir: [this.reservoir, Validators.required],
      hp: [0, Validators.required],
      he: [0, Validators.required],
      tm: [27, Validators.required],
      da: [0, Validators.required],
      ta: [25, Validators.required],
      d15: [0],
      lp: [0],
      leau: [0],
      la: [0],
      l15: [0],
      tmv: [0],
      coef: [0],
      type: ['STOCKAGE'],
      observation: ['']
    });

    // detect form changes
    this.pageForm.valueChanges.subscribe((data) => {
     
      if (data.type === 'FERMETURE') {
        data.heure = '16:00:00';
        this.heureJauge = '16:00:00';
      }

      if (data.type === 'STOCKAGE') {
        data.heure = '08:00:00';
        this.heureJauge = '08:00:00';
      }
      this.change = true;
    });
  }

  saveData(form: FormGroup) {
    if (!this.jaugeS.isJaugeEgale(form.value, this.jaugeages)) {
      if (form.valid) {
        this.loading = false;
        const newData = form.value;
        newData.d15 = this.jaugeS.densite15(newData.da, newData.ta);
        newData.coef = this.jaugeS.coef(newData.d15, newData.tm);
  
        this.jaugeS.create(newData)
        .subscribe(
          (data) => {
            this.pageForm.reset();
            this.loading = false;
            this.messageSuccess('Création');
            this.store.dispatch(new JaugeagesActions.Add(data));
            this.jaugeS.getData(this.jaugeS.url + '?ctrl=stock', 'stocks', 'setDataStore', SjtActions);
            setTimeout(() => {
              this.router.navigateByUrl('/vertical/jaugeage');
            }, 4300);
          },
          (error) => {
            this.loading = false;
            this.messageError();
          }
        );
      }
    } else {
      alert('Il existe déjà ce jaugeage ! ');
    }
  }
}
