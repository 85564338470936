import { IUserApp, Niveau } from './../../../interfaces/models';
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/http/data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService extends DataService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  create(user: IUserApp) {
    return this.post(user, '?ctrl=user&action=creer');
  }

  verifieRole(operation: string) {
     const niveau = JSON.parse(localStorage.getItem('myUser')).niveau;

     if (niveau === Niveau.superAdministrateur && operation !== 'jaugeage') {
      return false;
     } else {
       if (operation === 'jaugeage') {
         return false;
       }
     }

     return true;
  }

  supprimer(id: any) {
    return this.delete(id, '?ctrl=user&action=supprimer');
  }

  updateUser(user: IUserApp) {
    return this.update(user, '?ctrl=user&action=modifier');
  }

  changePassword(data: any) {
    return this.update(data, '?ctrl=user&action=changerPassword');
  }

}
