import * as JaugeageActions from './../actions/jaugeages.actions';
import { IJaugeage } from './../../interfaces/models';

export type Action = JaugeageActions.All;

// Reducer function
export function jaugeagesReducer(data: IJaugeage[] = [], action: Action) {
  switch (action.type) {
    case JaugeageActions.SET: {
      return action.data;
    }
    case JaugeageActions.EDIT: {
      data.forEach((c: IJaugeage, index) => {
        if (c.id === action.data.id) {
          data[index] = action.data;
        }
      });

      return [...data];
    }
    case JaugeageActions.ADD: {
      return [action.data, ...data];
    }
    case JaugeageActions.DELETE: {
      const newData = data.filter(c => {
        return c.id !== action.id;
      });
      
      return newData;
    }
    case JaugeageActions.OPEN_MODAL: {
      return true;
    }
    case JaugeageActions.CLOSE_MODAL: {
      return false;
    }
    default: {
      return data;
    }
  }
}
