import { LivraisonService } from './../livraison/livraison.service';
import { ILivraison, IDepot, Niveau } from './../../../interfaces/models';
import { IOption } from './../../../ui/interfaces/option';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { Content } from 'src/app/ui/interfaces/modal';
import { CommonService } from 'src/app/services/common/common.service';
import * as LivraisonActions from './../../../store/actions/livraisons.actions';
import * as SjtActions from './../../../store/actions/stocks.actions';
import { JaugeageService } from 'src/app/layout/vertical/jaugeage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transfert-attendu',
  templateUrl: './transfert-attendu.component.html',
  styleUrls: ['./transfert-attendu.component.scss']
})
export class TransfertAttenduComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  change: boolean;
  loading = false;
  dateDebut: Date;
  dateFin: Date;
  proprietaires: IOption[];
  destinataires: IOption[];
  proprietaire: IOption;
  destinataire: IOption;
  livraisons: ILivraison[];
  opt = { year: 'numeric', month: 'long', day: 'numeric' };
  date_recu: any = null;
  heure_recu: any;
  transfertAttendu = null;
  depots: IOption[];
  depot: IOption;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private commonS: CommonService,
    private livraiS: LivraisonService,
    private jaugeS: JaugeageService,
    private router: Router
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: '',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'transfert dépot attendu'
        }
      ]
    };
    this.change = false;
    this.dateDebut = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.dateFin = new Date();
    this.proprietaire = {label: '-- TOUS --', value: '0'};
    this.destinataire = {label: '-- TOUS --', value: '0'};
    this.proprietaires = [];
    this.destinataires = [];
    this.proprietaires[0] = {label: '-- TOUS --', value: '0'};
    this.destinataires[0] = {label: '-- TOUS --', value: '0'};
    this.livraisons = [];
    this.initData();
    this.heure_recu = this.commonS.hourString(new Date());
    this.depots = [];
    this.depot = {label: '', value: ''};
  }

  initData() {
    const data: ILivraison = {
      numero: null
    };
    this.livraisons.push(data);
  }

  ngOnInit() {
    super.ngOnInit();
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    const depotUser: IDepot = userInfo.depots_id;
    const roleUser = userInfo.niveau;
    this.store.select('persons').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(person => {
          if (person.type === 'PROPRIETAIRE') {
            const c: IOption = {label: '', value: ''};
            c.label = person.nom.toUpperCase();
            c.value = person.id;
            this.proprietaires.push(c);
          }

          if (person.type === 'CLIENT') {
            const c: IOption = {label: '', value: ''};
            c.label = person.nom.toUpperCase();
            c.value = person.id;
            this.destinataires.push(c);
          }
        });
      }
    });

    this.store.select('depots').subscribe(depots => {
      if (depots && depots.length) {
        if (roleUser === Niveau.superAdministrateur || roleUser === Niveau.admin) {
          depots.forEach(depot => {
            const c: IOption = {label: '', value: ''};
            c.label = depot.nom.toUpperCase();
            c.value = depot.id;
            this.depots.push(c);
          });
        } else {
          const c: IOption = {label: '', value: ''};
          c.label = depotUser.nom.toUpperCase();
          c.value = depotUser.id;
          this.depots.push(c);
        }

        this.depot = this.depots[0];
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.transfertAttendu = row;
    this.modal.open({
      body,
      header,
      footer,
      options : { height: 'auto', width: 600, closeButton: false, overlay: true, overlayClose: true }
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
  }

  afficher() {
    this.store.select('livraisons').subscribe(datas => {
      this.livraisons = [];
      this.initData();
      const total: ILivraison = {
        d15: 'TOTAL',
        la: 0,
        l15: 0
      };
      if (datas && datas.length) {
        this.livraisons.pop();
        datas.forEach(livr => {
          if (livr.type === 'BT' && livr.iddepot_destinateur === this.depot.value && livr.date_recu === null ) {
            if ((new Date(livr.date ) >= this.dateDebut) && (new Date(livr.date ) <= this.dateFin)) {
              this.livraisons.push(livr);
              total.la += livr.la;
              total.l15 += livr.l15;
            }
          }
        });
        if (!this.livraisons.length) {
          this.livraisons = [];
          this.initData();
        } else {
          this.livraisons.push(total);
        }
      }
    });
  }

}
