import { ILivraison, Niveau } from './../../../interfaces/models';
import { IOption } from './../../../ui/interfaces/option';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { Content } from 'src/app/ui/interfaces/modal';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-livraison',
  templateUrl: './livraison.component.html',
  styleUrls: ['./livraison.component.scss']
})
export class LivraisonComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  change: boolean;
  loading = false;
  dateDebut: Date;
  dateFin: Date;
  proprietaires: IOption[];
  destinataires: IOption[];
  proprietaire: IOption;
  destinataire: IOption;
  livraisons: ILivraison[];
  depotUser: any;
  roleUser: any;
  opt = { year: 'numeric', month: 'long', day: 'numeric' };

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private commonS: CommonService
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: '',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'livraison'
        }
      ]
    };
    this.change = false;
    this.dateDebut = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.dateFin = new Date();
    this.proprietaire = {label: '-- TOUS --', value: '0'};
    this.destinataire = {label: '-- TOUS --', value: '0'};
    this.proprietaires = [];
    this.destinataires = [];
    this.proprietaires[0] = {label: '-- TOUS --', value: '0'};
    this.destinataires[0] = {label: '-- TOUS --', value: '0'};
    this.livraisons = [];
    this.initData();
    this.afficher();
  }

  initData() {
    const data: ILivraison = {
      numero: null
    };
    this.livraisons.push(data);
  }

  ngOnInit() {
    super.ngOnInit();

    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    if (userInfo.depots_id) {
      this.depotUser = userInfo.depots_id.id;
    }
    this.roleUser = userInfo.niveau;
    this.store.select('persons').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(person => {
          if (person.type === 'PROPRIETAIRE') {
            const c: IOption = {label: '', value: ''};
            c.label = person.nom.toUpperCase();
            c.value = person.id;
            this.proprietaires.push(c);
          }

          if (person.type === 'CLIENT') {
            const c: IOption = {label: '', value: ''};
            c.label = person.nom.toUpperCase();
            c.value = person.id;
            this.destinataires.push(c);
          }
        });
      }
    });

    this.afficher();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.modal.open({
      body,
      header,
      footer,
      options : { height: 'auto', width: 600, closeButton: false, overlay: true, overlayClose: true }
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
  }

  afficher() {
    this.store.select('livraisons').subscribe(datas => {
      this.livraisons = [];
      this.initData();
      if (datas && datas.length) {
        const total: ILivraison = {
          d15: 'TOTAL',
          la: 0,
          l15: 0
        };
        this.livraisons.pop();
        datas.forEach(livr => {
          if (livr.type === 'LIVRAISON') {
            if (this.roleUser === Niveau.superAdministrateur || this.roleUser === Niveau.admin) {
              if ((new Date(livr.date ) >= this.dateDebut) && (new Date(livr.date ) <= this.dateFin)) {
                total.la += livr.la;
                total.l15 += livr.l15;
                this.livraisons.push(livr);
              }
            } else {  
              if ((new Date(livr.date ) >= this.dateDebut) && (new Date(livr.date ) <= this.dateFin)  && livr.id_depot === this.depotUser) {
                total.la += livr.la;
                total.l15 += livr.l15;
                this.livraisons.push(livr);
              }
            }
          }
        });

        if (!this.livraisons.length) {
          this.livraisons = [];
          this.initData();
        } else {
          this.livraisons.push(total);
        }
      }
    });
  }
}
