export interface IUserApp {
    id?: any;
    pseudo?: string;
    nom?: string;
    niveau?: Niveau;
    password?: string;
    etat?: Etat;
    token?: string;
    depots_id?: IDepot;
}

export interface IDepot {
    id?: any;
    nom?: string;
    localite?: string;
    type?: TypeDepot;
}

export interface IProduit {
    id?: any;
    nature?: string;
    code?: string;
}

export interface IPerson {
    id?: any;
    nom?: string;
    postnom?: string;
    prenom?: string;
    type?: TypePerson;
    sexe?: Sexe;
    numero_reference?: number;
    adresse?: string;
}

export interface IContenant {
    id?: any;
    type?: TypeContenant;
    nom?: string;
    code?: string;
    capacite?: number;
    depots_id?: IDepot;
    produits_id?: IProduit;
    persons_id?: IPerson;
}

export interface IJaugeage {
    id?: any;
    date?: any;
    heure?: any;
    hp?: number;
    he?: number;
    tm?: number;
    tmv?: number;
    da?: number;
    ta?: number;
    lp?: number;
    la?: number;
    l15?: number;
    d15?: number;
    coef?: number;
    leau?: number;
    observation?: string;
    reservoir?: any;
    idreservoir?: number;
    produit?: any;
    idproduit?: any;
    iddepot?: any;
    type?: string;
    libelle?: any;
}

export interface IReception {
    id?: number;
    numero?: number;
    conteneur?: string;
    idconteneur?: any;
    fournisseur?: string;
    idperson?: number;
    origine?: string;
    produit?: string;
    idproduit?: number;
    depot?: number;
    quantite?: number;
    etat?: Etat_dech;
    date_acostage?: any;
    heure_acostage?: any;
    date_debut?: Date;
    date_fin?: Date;
    date_depart?: any;
    heure_debut?: Date;
    heure_fin?: Date;
    heure_depart?: any;
    total15?: number;
    total_ambiant?: number;
    slop?: number;
    receptions?: any[];
}

export interface ILivraison {
    id?: number;
    numero?: number;
    numero_bl?: number;
    l15?: number;
    la?: number;
    d15?: any;
    tm?: any;
    coef?: number;
    index_avant?: number;
    index_apre?: number;
    observation?: string;
    id_depot?: number;
    client?: any;
    idclient?: number;
    destination?: any;
    iddestination?: number;
    proprietaire?: any;
    idproprietaire?: number;
    depot?: any;
    date?: any;
    heure?: any;
    tank?: any;
    idreservoir?: number;
    depot_expediteur?: any;
    depot_destinateur?: any;
    iddepot_expediteur?: any;
    iddepot_destinateur?: any;
    date_recu?: any;
    heure_recu?: any;
    tank_recu?: any;
    idtank_recu?: any;
    type?: string;
}

export interface IStocks {
    id?: number;
   date?: any;
   heure?: any;
   tank?: string;
   reservoir?: any;
   hp?: number;
   he?: number;
   tm?: number;
   lp?: number;
   da?: number;
   ta?: number;
   la?: number;
   l15?: number;
   d15?: number;
   coef?: number;
   leau?: number;
   l15recp?: number;
   bl?: number;
   consm?: number;
   bt?: number;
   bti?: number;
   divers?: number;
   totalsortie?: number;
   totalprodtheor?: any;
   gain?: number;
   perte?: number;
   observation?: string;
   type?: string;
   iddepot?: any;
}

export interface IGestion {
    id?: number;
   date?: any;
   heure?: any;
   tank?: string;
   reservoir?: any;
   la?: number;
   l15?: number;
   l15recp?: number;
   l15recpa?: number;
   bl?: number;
   consm?: number;
   bt?: number;
   rbt?: number;
   rbta?: number;
   bla?: number;
   consma?: number;
   bta?: number;
   bti?: number;
   btia?: number;
   rbti?: number;
   rbtia?: number;
   divers?: number;
   totalsortie?: number;
   totalprodtheor?: any;
   gain?: number;
   perte?: number;
   totalsortiea?: number;
   totalprodtheora?: any;
   gaina?: number;
   pertea?: number;
   type?: string;
   iddepot?: any;
   typecontenant?: string;
}

export interface ITransfert {
    id?: number;
    numero?: number;
    date?: any;
    heure?: any;
    id_depot?: any;
    tank_depart?: any;
    tank_destination?: any;
    total15?: number;
    total_ambiant?: number;
    idtank_depart?: number;
    idtank_destination?: number;
}

export interface IDechargement {
    id?: number;
    numero?: number;
    conteneur?: IContenant;
    idconteneur?: any;
    origine?: string;
    fournisseur?: IPerson;
    idperson?: number;
    produit?: IProduit;
    idproduit?: number;
    quantite?: number;
    etat?: Etat_dech;
    date_acostage?: Date;
    date_debut?: Date;
    date_fin?: Date;
    date_depart?: Date;
}

export interface IPdf {
    id?: any;
    libelle?: string;
    LA?: any;
    L15?: any;
  }
  

export enum Niveau {
    operateur = 'OPERATEUR_DEPOT',
    responsable = 'RESPONSABLE_DEPOT',
    admin = 'ADMIN_DEPOT',
    superAdministrateur = 'SUPER_ADMIN'
}

export enum Etat {
    actif = 'ACTIF',
    inactif = 'INACTIF'
}

enum Etat_dech {
    attendu = 'ATTENDU',
    termine = 'TERMINE',
    en_cours = 'DECHARG_TERMINE'
}

export enum TypeDepot {
    reel = 'REEL',
    fictif = 'FICTIF'
}
export enum TypeContenant {
    reservoir = 'RESERVOIR',
    tuyauterie = 'TUYAUTERIE',
    conteneur = 'CONTENEUR',
    tanker = 'TANKER',
    barge = 'BARGE',
    wagon = 'CAMION_CITERNE',
    camion = 'WAGON_CITERNE'
}

enum TypeSortie {
    livraison = 'LIVRAISON',
    transfer = 'TRANSFERT'
}

export enum Sexe {
    homme = 'homme',
    femme = 'femme'
}

export enum TypePerson {
    fournisseur = 'FOURNISSEUR',
    proprietaire = 'PROPRIETAIRE',
    client = 'CLIENT'
}
