import { Injectable } from '@angular/core';
import { AuthentificationService } from './authentification.service';
import { Router, RouterStateSnapshot, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private auth: AuthentificationService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isLogin()) { return true; }
    this.router.navigate(['/public/sign-in'], {queryParams: {returnUrl: state.url}});
    return false;
  }

}
