import { IReception, IDechargement, Niveau } from './../../../interfaces/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Content } from 'src/app/ui/interfaces/modal';
import { ReceptionService } from '../reception/reception.service';
import { CommonService } from 'src/app/services/common/common.service';
import { JaugeageService } from 'src/app/layout/vertical/jaugeage.service';
import * as SjtActions from './../../../store/actions/stocks.actions';
import * as DechActions from '../../../store/actions/dechargements.actions';

@Component({
  selector: 'app-reception-attendue',
  templateUrl: './reception-attendue.component.html',
  styleUrls: ['./reception-attendue.component.scss']
})
export class ReceptionAttendueComponent extends BasePageComponent implements OnInit, OnDestroy {

  receptions: IReception[];
  pageForm: FormGroup;
  change: boolean;
  loading = false;
  dateAttendue: Date;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private comS: CommonService,
    private receptionS: ReceptionService,
    private jaugeS: JaugeageService,
    private fb: FormBuilder
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: '',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'liste tankers attendus'
        }
      ]
    };
    this.change = false;
    this.receptions = [];
    this.dateAttendue = new Date();
    this.initData();
  }

  ngOnInit() {
    super.ngOnInit();
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    const depotUser = userInfo.depots_id;
    const roleUser = userInfo.niveau;

    this.store.select('dechargements').subscribe(datas => {
      if (datas && datas.length) {
        console.log('daonnee', datas);
        this.receptions.pop();
        datas.forEach(dech => {
          if (roleUser === Niveau.superAdministrateur || roleUser === Niveau.admin) {
            if (dech.etat !== 'TERMINE') {
              this.receptions.push(dech);
            }
          } else {
            if (dech.etat !== 'TERMINE' && dech.depot === depotUser.id ) {
              this.receptions.push(dech);
            }
          }
        });

        if (!this.receptions.length) {
          this.receptions = [];
          this.initData();
        }
      }
    });
  }

  initData() {
    const data: IReception = { numero: null };
    this.receptions.push(data);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.initForm(row);
    this.modal.open({
      body,
      header,
      footer,
      options : { height: 'auto', width: 600, closeButton: false, overlay: true, overlayClose: true }
    });
  }

  // init form
  initForm(data: any) {
    if (data) {
      this.pageForm = this.fb.group({
        date: [new Date(), Validators.required],
        heure: [this.comS.hourString(new Date()), Validators.required],
        numero: [data.numero],
        tanker: [data.conteneur]
      });
    }

    // detect form changes
    this.pageForm.valueChanges.subscribe(() => {
      this.change = true;
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
  }

  saveDepart() {
    const newData = this.pageForm.value;

    this.receptionS.saveDepart(newData)
      .subscribe(
        (data) => {
          this.loading = false;
          this.closeModal();
          this.messageSuccess('Modification');
          this.store.dispatch(new DechActions.Add(data));
          this.jaugeS.getData(this.jaugeS.url + '?ctrl=dechargement', 'dechargements', 'setDataStore', DechActions);
          this.jaugeS.getData(this.jaugeS.url + '?ctrl=stock', 'stocks', 'setDataStore', SjtActions);
        },
        (error) => {
          this.loading = false;
          this.messageError();
        }
      );
  }

}
