import { IContenant } from '../../interfaces/models';
import { Action } from '@ngrx/store';

export const SET = '[Contenants] Set';
export const ADD = '[Contenants] Add';
export const EDIT = '[Contenants] Edit';
export const DELETE = '[Contenants] Delete';
export const OPEN_MODAL = '[Contenants] Open modal';
export const CLOSE_MODAL = '[Contenants] Close modal';

export class Set implements Action {
  readonly type = SET;

  constructor(public data: IContenant[]) {}
}

export class Add implements Action {
  readonly type = ADD;

  constructor(public data: IContenant) {}
}

export class Edit implements Action {
  readonly type = EDIT;

  constructor(public data: IContenant) {}
}

export class Delete implements Action {
  readonly type = DELETE;

  constructor(public id: any) {}
}

export class OpenModal implements Action {
  readonly type = OPEN_MODAL;
}

export class CloseModal implements Action {
  readonly type = CLOSE_MODAL;
}

export type All = Set | Add | Edit | Delete | OpenModal | CloseModal;
