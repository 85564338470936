import { DechargementService } from './../dechargement/dechargement.service';
import { IReception, IJaugeage } from './../../../interfaces/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { ActivatedRoute, Params } from '@angular/router';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-rapport-dechargement',
  templateUrl: './rapport-dechargement.component.html',
  styleUrls: ['./rapport-dechargement.component.scss']
})
export class RapportDechargementComponent extends BasePageComponent implements OnInit, OnDestroy  {

  pageForm: FormGroup;
  loading = false;
  change: boolean;
  numero: number;
  rdd: any[];
  jaugeages: any[];
  jauges: any[];
  totaux: any[];
  
  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private route: ActivatedRoute,
    private dechargeS: DechargementService
  ) { 
    super(store, httpSv, modal);
    this.pageData = {
      title: '',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'rapport de dechargement'
        }
      ]
    };

    this.change = false;
    this.numero = 0;
    this.rdd = [];
    this.jaugeages = [];
    this.jauges = [];
    this.totaux = [];
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.params.subscribe(
      (params: Params) => {
        this.numero = +params.numero;
      }
    );

    this.dechargeS.rdd(this.numero)
    .subscribe(
      (datas: any[]) => {
        console.log('donnnee ', datas);

        const total: IJaugeage = {
          libelle: 'TOTAL',
          la: 0,
          l15: 0,
          tmv: 0
        };

        let soustotal: IJaugeage = {
          libelle: 'SOUS-TOTAL',
          la: 0,
          l15: 0,
          tmv: 0
        };
        let ecart = 0;
        let produit = null;

        const rdd = datas[0].rdd;
        rdd.date_fin = datas[datas.length - 1].rdd.date_fin;
        this.rdd.push(rdd);

        datas.forEach(data => {
          soustotal = {
            libelle: 'SOUS-TOTAL',
            la: 0,
            l15: 0,
            tmv: 0
          };
          total.la += data.rdd.total_ambiant;
          total.l15 += data.rdd.total15;
          total.tmv += data.rdd.total_tmv;
          produit = data.rdd.produit;

          soustotal.la = data.rdd.total_ambiant;
          soustotal.l15 = data.rdd.total15;
          soustotal.tmv = data.rdd.total_tmv;
  
          this.jaugeages.push(data.apres);
          this.jaugeages.push(data.avant);
          this.jaugeages.push(soustotal);
        });

        const tanks = datas[datas.length - 1].tank;

        tanks.forEach(tank => {
          soustotal = {
            libelle: 'SOUS-TOTAL',
            la: 0,
            l15: 0,
            tmv: 0
          };
          const jauges = this.jaugeages.filter(jaug => jaug.reservoir === tank);
          
          this.jauges.push(jauges[jauges.length - 2]);
          this.jauges.push(jauges[1]);

          soustotal.la = jauges[jauges.length - 2].la - jauges[1].la;
          soustotal.l15 = jauges[jauges.length - 2].l15 - jauges[1].l15;
          soustotal.tmv = jauges[jauges.length - 2].tmv - jauges[1].tmv;
          this.jauges.push(soustotal);


        });

        ecart = total.tmv - rdd.quantite;

        const totaux: any = {
          produit,
          total_ambiant: total.la,
          total15: total.l15,
          total_tmv: total.tmv,
          ecart
        };

        this.jauges.push({});
        this.jauges.push(total);
        this.totaux.push(totaux);
      },
      (error) => {
        alert('Le serveur a rencontré un problème. Vueillez recommencer !');
      }
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  creerPDF() {
    const data = document.getElementById('pdfTable');
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('l', 'cm', 'a4'); // Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'JPEG', 0, 0, 31, 17.0);
      pdf.save('rapport-dechargement.pdf');
    });
  }
}
