import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/http/data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LivraisonService extends DataService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  create(data: any) {
    return this.post(data, '?ctrl=sortie&action=creer');
  }
  supprimer(id: any) {
    return this.delete(id, '?ctrl=sortie&action=supprimer');
  }

  updateSortie(data: any) {
    return this.update(data, '?ctrl=sortie&action=modifier');
  }

  confirmer(data: any) {
    return this.post(data, '?ctrl=sortie&action=confirmer');
  }

}
