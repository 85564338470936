import { IUserApp } from './../../interfaces/models';
import { IUser } from './../../ui/interfaces/user';
import { Injectable } from '@angular/core';
import { JwtHelper, tokenNotExpired } from 'angular2-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  constructor() { }

  isLogin() {
    const user = localStorage.getItem('myUser');
    if (user) {
      return true;
    }

    return false;
  }

  logout() {
    localStorage.clear();
  }

  getCurrentUser() {
    const user: IUserApp = JSON.parse(localStorage.getItem('myUser'));
    // if ( !token) { return null; }

    // return new JwtHelper().decodeToken(token);

    if (user) {
      return user.id ;
    }

    return 0;

  }
}
