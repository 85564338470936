import * as UsersActions from '../actions/users.actions';
import { IUserApp } from '../../interfaces/models';

export type Action = UsersActions.All;

// Reducer function
export function usersReducer(data: IUserApp[] = [], action: Action) {
  switch (action.type) {
    case UsersActions.SET: {
      return action.data;
    }
    case UsersActions.EDIT: {
      data.forEach((user: IUserApp, index) => {
        if (user.id === action.data.id) {
          data[index] = action.data;
        }
      });

      return [...data];
    }
    case UsersActions.ADD: {
      return [action.data, ...data];
    }
    case UsersActions.DELETE: {
      const newData = data.filter((user: IUserApp) => {
        return user.id !== action.id;
      });

      console.log(newData);
      return newData;
    }
    case UsersActions.OPEN_MODAL: {
      return true;
    }
    case UsersActions.CLOSE_MODAL: {
      return false;
    }
    default: {
      return data;
    }
  }
}
