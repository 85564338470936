import { JaugeageService } from 'src/app/layout/vertical/jaugeage.service';
import { CommonService } from './../../../services/common/common.service';
import { IOption } from './../../../ui/interfaces/option';
import { IStocks, Niveau, IDepot, IPdf } from './../../../interfaces/models';
import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { FormBuilder } from '@angular/forms';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'page-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent extends BasePageComponent implements OnInit, OnDestroy {

  loading = false;
  dateSjr: Date;
  stocks: IPdf[];
  dateDebut: any;
  dateFin: any;
  date = new Date();
  opt = { year: 'numeric', month: 'long', day: 'numeric' };
  depotcheck = true;
  depots: IOption[];
  depot: IOption;
  typeStocks: IOption[];
  typeStock: IOption;

  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    private fb: FormBuilder,
    private comS?: CommonService,
    private jaugeageS?: JaugeageService
  ) {
    super(store, httpSv);

    this.pageData = {
      title: '',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Dashboards'
        }
      ]
    };
  
    this.dateDebut = comS.dateString(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    this.dateFin = comS.dateString(new Date());
    this.initData();
    this.depots = [];
    this.depot = {label: '', value: ''};
    this.typeStocks = [
      {label: 'Opérationnel', value: 'operationnel'},
      {label: 'Final', value: 'final'}
    ];
    this.typeStock = {label: 'Opérationnel', value: 'operationnel'};
  }

  initData() {
    this.stocks = [
      {libelle: '1. STOCK DEBUT', LA: 0, L15: 0},
      {libelle: '2. ENTREES', LA: '-', L15: '-'},
      {libelle: '2.1. RAPPORT DE DÉCHARGEMENT', LA: 0, L15: 0},
      {libelle: '2.2. RÉCEPTION BON DE TRANSFERT', LA: 0, L15: 0},
      {libelle: '2.3. RÉCEPTION TRANSFERT INTERNE', LA: 0, L15: 0},
      {libelle: '3. TOTAL DES ENTRÉES', LA: 0, L15: 0},
      {libelle: '4. SORTIES', LA: '-', L15: '-'},
      {libelle: '4.1. BORDEAU DE LIVRAISON', LA: 0, L15: 0},
      {libelle: '4.2. BON DE TRANSFERT', LA: 0, L15: 0},
      {libelle: '4.3. CONSOMMATION', LA: 0, L15: 0},
      {libelle: '4.4. TRANSFERT INTERNE', LA: 0, L15: 0},
      {libelle: '5. TOTAL SORTIES', LA: 0, L15: 0},
      {libelle: '6. STOCK THÉORIQUE', LA: 0, L15: 0},
      {libelle: '7. STOCK FIN JAUGÉ', LA: 0, L15: 0},
      {libelle: '8. TUYAUTERIE FIN', LA: 0, L15: 0},
      {libelle: '9. STOCK FIN GLOBAL', LA: 0, L15: 0},
      {libelle: '10. ÉCART D\'EXPLOITATION', LA: 0, L15: 0},
      {libelle: '11. pourcentage écart', LA: 0, L15: 0},
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    const depotUser: IDepot = userInfo.depots_id;
    const roleUser = userInfo.niveau;

    this.store.select('depots').subscribe(depots => {
      if (depots && depots.length) {
        if (roleUser === Niveau.superAdministrateur || roleUser === Niveau.admin) {
          depots.forEach(depot => {
            const c: IOption = {label: '', value: ''};
            c.label = depot.nom.toUpperCase();
            c.value = depot.id;
            this.depots.push(c);
          });
        } else {
          const c: IOption = {label: '', value: ''};
          c.label = depotUser.nom.toUpperCase();
          c.value = depotUser.id;
          this.depots.push(c);
        }

        this.depot = this.depots[0];
      }
    });

    this.voirSJR();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  voirSJR() {
    if (this.depot.value && this.dateDebut && this.dateFin) {
      this.initData();

      this.store.select('gestions').subscribe(stocks => {
        if (stocks && stocks.length) {
          stocks.forEach(stock => {
            if (stock.iddepot === this.depot.value && stock.type !== 'FERMETURE') {
              if (stock.date === this.dateDebut && stock.heure === '08:00:00') {
                this.stocks[0].LA += stock.la;
                this.stocks[0].L15 += stock.l15;
              }

              // entrée et sortie
              if (stock.date >= this.dateDebut && (new Date(stock.date)).getTime() <= (new Date(this.dateFin).getTime() - 86400000)) {
                this.stocks[2].LA += stock.l15recpa;
                this.stocks[2].L15 += stock.l15recp;

                this.stocks[3].LA += stock.rbta;
                this.stocks[3].L15 += stock.rbt;

                this.stocks[4].LA += stock.rbtia;
                this.stocks[4].L15 += stock.rbti;

                this.stocks[5].LA += stock.l15recpa + stock.rbta + stock.rbtia;
                this.stocks[5].L15 += stock.l15recp + stock.rbt + stock.rbti;

                this.stocks[7].LA += stock.bla;
                this.stocks[7].L15 += stock.bl;

                this.stocks[8].LA += stock.bta;
                this.stocks[8].L15 += stock.bt;

                this.stocks[9].LA += stock.consma ;
                this.stocks[9].L15 += stock.consm;

                this.stocks[10].LA += stock.btia;
                this.stocks[10].L15 += stock.bti;

                this.stocks[11].LA += stock.bla + stock.bta + stock.consma + stock.btia;
                this.stocks[11].L15 += stock.bl + stock.bt + stock.consm + stock.bti;
              }
            }

            if (this.typeStock.value === 'operationnel') {
              if (stock.type === 'FERMETURE' && stock.iddepot === this.depot.value && stock.typecontenant !== 'TUYAUTERIE') {
                if ((new Date(stock.date)).getTime() === (new Date(this.dateFin).getTime() - 86400000)) {
                  this.stocks[13].LA += stock.la;
                  this.stocks[13].L15 += stock.l15;
                }
              }
  
              if (stock.type === 'FERMETURE' && stock.iddepot === this.depot.value && stock.typecontenant === 'TUYAUTERIE') {
                if ((new Date(stock.date)).getTime() === (new Date(this.dateFin).getTime() - 86400000)) {
                  this.stocks[14].LA += stock.la;
                  this.stocks[14].L15 += stock.l15;
                }
              }
            }

            if (this.typeStock.value === 'final') {
              if (stock.type !== 'FERMETURE' && stock.iddepot === this.depot.value && stock.typecontenant !== 'TUYAUTERIE') {
                if (stock.date === this.dateFin && stock.heure === '08:00:00') {
                  this.stocks[13].LA += stock.la;
                  this.stocks[13].L15 += stock.l15;
                }
              }
  
              if (stock.type !== 'FERMETURE' && stock.iddepot === this.depot.value && stock.typecontenant === 'TUYAUTERIE') {
                if (stock.date === this.dateFin && stock.heure === '08:00:00') {
                  this.stocks[14].LA += stock.la;
                  this.stocks[14].L15 += stock.l15;
                }
              }
            }
          });

          // stock théoriquz
          this.stocks[12].LA +=  this.stocks[0].LA +  this.stocks[5].LA -  this.stocks[11].LA;
          this.stocks[12].L15 += this.stocks[0].L15 +  this.stocks[5].L15 -  this.stocks[11].L15;

          // stock global
          this.stocks[15].LA +=  this.stocks[13].LA +  this.stocks[14].LA;
          this.stocks[15].L15 += this.stocks[13].L15 +  this.stocks[14].L15;

          // ecart
          this.stocks[16].LA +=  this.stocks[15].LA -  this.stocks[12].LA;
          this.stocks[16].L15 += this.stocks[15].L15 -  this.stocks[12].L15;

          // poucentage
          // tslint:disable-next-line:max-line-length
          this.stocks[17].LA +=  Math.round(((this.stocks[16].LA * 100) / (this.stocks[7].LA + this.stocks[8].LA + this.stocks[9].LA )) * 100) / 100;
          // tslint:disable-next-line:max-line-length
          this.stocks[17].L15 +=  Math.round(((this.stocks[16].L15 * 100) / (this.stocks[7].L15 + this.stocks[8].L15 + this.stocks[9].L15 )) * 100) / 100;
        }
      });
    } else {
      alert('Veuillez remplir tous les champs !');
    }
  }

  creerPDF() {
    const data = document.getElementById('pdfTable');
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('l', 'cm', 'a4'); // Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'JPEG', 0, 0, 32, 20.8);
      pdf.save('stocks.pdf');
    });

  }

}
