import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { en_US, NgZorroAntdModule, NZ_I18N } from 'ng-zorro-antd';

import { AppComponent } from './app.component';
import { ROUTES, RoutingModule } from './routing/routing.module';
import { LayoutModule } from './layout/layout.module';
import { UIModule } from './ui/ui.module';
import { PagesModule } from './pages/pages.module';
import { pageDataReducer } from './store/reducers/page-data.reducer';
import { appSettingsReducer } from './store/reducers/app-settings.reducer';
import { patientsReducer } from './store/reducers/patients.reducer';
import { usersReducer } from './store/reducers/users.reducer';
import { depotsReducer } from './store/reducers/depots.reducer';
import { contenantsReducer } from './store/reducers/contenants.reducer';
import { jaugeagesReducer } from './store/reducers/jaugeage.reducer';
import { produitsReducer } from './store/reducers/produits.reducer';
import { personsReducer } from './store/reducers/persons.reducer';
import { dechargementsReducer } from './store/reducers/dechargements.reducer';
import { stocksReducer } from './store/reducers/stocks.reducer';
import { livraisonsReducer } from './store/reducers/livraisons.reducer';
import { transfertsReducer } from './store/reducers/transferts.reducer';
import { gestionsReducer } from './store/reducers/gestions.reducer';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES),
    StoreModule.forRoot({
      pageData: pageDataReducer,
      appSettings: appSettingsReducer,
      patients: patientsReducer,
      users: usersReducer,
      depots: depotsReducer,
      contenants: contenantsReducer,
      jaugeages: jaugeagesReducer,
      produits: produitsReducer,
      persons: personsReducer,
      dechargements: dechargementsReducer,
      stocks: stocksReducer,
      livraisons: livraisonsReducer,
      transferts: transfertsReducer,
      gestions: gestionsReducer
    }),
    NgZorroAntdModule,
    RoutingModule,
    LayoutModule,
    UIModule,
    PagesModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
