import { IContenant } from './../../../interfaces/models';
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/http/data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReservoirService extends DataService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  create(data: IContenant) {
    return this.post(data, '?ctrl=contenant&action=creer');
  }
  supprimer(id: any) {
    return this.delete(id, '?ctrl=contenant&action=supprimer');
  }

  updateContenant(data: IContenant) {
    return this.update(data, '?ctrl=contenant&action=modifier');
  }
}
