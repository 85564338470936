import { CommonService } from './../../../services/common/common.service';
import { ReceptionService } from './reception.service';
import { IReception, Niveau } from './../../../interfaces/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Content } from 'src/app/ui/interfaces/modal';
import { IOption } from 'src/app/ui/interfaces/option';

@Component({
  selector: 'app-reception',
  templateUrl: './reception.component.html',
  styleUrls: ['./reception.component.scss']
})
export class ReceptionComponent extends BasePageComponent implements OnInit, OnDestroy {

  receptions: IReception[];
  pageForm: FormGroup;
  change: boolean;
  loading = false;
  dateDebut: Date;
  dateFin: Date;
  depotuser: string;
  reservoirs: IOption[];
  reservoir: IOption;
  opt = { year: 'numeric', month: 'long', day: 'numeric' };

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private receptionService: ReceptionService,
    private commonS: CommonService
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: '',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'historique tanker'
        }
      ]
    };
    this.change = false;
    this.dateDebut = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.dateFin = new Date();
    this.reservoirs = [];
    this.reservoirs[0] = {label: '-- TOUS --', value: '0'};
    this.reservoir = {label: '-- TOUS --', value: '0'};
    this.depotuser = '';
    this.receptions = [];
    this.initData();
  }

  initData() {
    const data: IReception = { numero: null };
    this.receptions.push(data);
  }

  ngOnInit() {
    super.ngOnInit();

    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    if (userInfo.depots_id) {
      this.depotuser = userInfo.depots_id.nom.toUpperCase();
    }
    const depotId =  userInfo.depots_id.id;
    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(contenant => {
          if (contenant.type === 'CONTENEUR') {
            const c: IOption = {label: '', value: ''};
            c.label = contenant.nom.toUpperCase();
            c.value = contenant.id;
            this.reservoirs.push(c);
          }
        });
      }
    });

    this.afficher();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.modal.open({
      body,
      header,
      footer,
      options : { height: 'auto', width: 600, closeButton: false, overlay: true, overlayClose: true }
    });
  }

  afficher() {
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    const depotUser = userInfo.depots_id;
    const roleUser = userInfo.niveau;
    
    console.log('reservoir', this.reservoir.value);
    this.receptions = [];
    this.initData();
    if (this.reservoir.value !== '0') {
      this.store.select('dechargements').subscribe(datas => {
        if (datas && datas.length) {
          console.log('deonnee ', datas);
          this.receptions.pop();
          datas.forEach(recept => {
            if (roleUser === Niveau.superAdministrateur || roleUser === Niveau.admin) {
              // tslint:disable-next-line:max-line-length
              if (recept.idconteneur === this.reservoir.value && (new Date(recept.date_depart)) >= this.dateDebut && (new Date(recept.date_depart)) <= this.dateFin && recept.etat === 'TERMINE') {
                this.receptions.push(recept);
              }
            } else {
              // tslint:disable-next-line:max-line-length
              if (recept.idconteneur === this.reservoir.value && recept.depot === depotUser.id && (new Date(recept.date_depart)) >= this.dateDebut && (new Date(recept.date_depart)) <= this.dateFin && recept.etat === 'TERMINE') {
                this.receptions.push(recept);
              }
            }
          });
          if (this.receptions.length <= 0) {
            this.receptions = [];
            this.initData();
          }
        }
      });
    } else {
      this.store.select('dechargements').subscribe(datas => {
        if (datas && datas.length) {
          this.receptions.pop();
          
          datas.forEach(recept => {
            if (roleUser === Niveau.superAdministrateur || roleUser === Niveau.admin) {
              // tslint:disable-next-line:max-line-length
              if ((new Date(recept.date_depart)) >= this.dateDebut && (new Date(recept.date_depart)) <= this.dateFin && recept.etat === 'TERMINE') {
                this.receptions.push(recept);
              }
            } else {
              // tslint:disable-next-line:max-line-length
              if (recept.depot === depotUser.id && (new Date(recept.date_depart)) >= this.dateDebut && (new Date(recept.date_depart)) <= this.dateFin && recept.etat === 'TERMINE') {
                this.receptions.push(recept);
              }
            }
          });

          if (this.receptions.length <= 0) {
            this.receptions = [];
            this.initData();
          }
        }
      });
    }
  }

  // close modal window
  closeModal() {
    this.modal.close();
  }
}
