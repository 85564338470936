import { AuthentificationService } from './../common/authentification.service';
import { InternalServerError } from './../errors/InternalServerError';
import { UnauthorizedError } from '../errors/unauthorized-error';
import { ForbidenError } from '../errors/forbiden-error';
import { BadInputError } from '../errors/bad-input-error';
import { NotFoundError } from '../errors/not-found-error';
import { AppError } from '../errors/app-error';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  protected readonly BASE_URL = 'https://kuntualagestionpetro.bbhsarlu.com/gestiontankphp/';
  private idUser: any;
  private auth: AuthentificationService = new AuthentificationService();

  constructor(
    public http: HttpClient
    ) {
      this.idUser = this.auth.getCurrentUser();
    }

  // get one data in server
  getOne(id: string, url: string) {
    return this.http.get(this.BASE_URL + url + id + '&idUser=' + this.idUser, {responseType: 'json'})
    .pipe(
      tap( (res: any) => {
        return res;
        }),
      catchError(this.handleError)
    );
  }

  // get all data in server
  getAll(url: string) {
    return this.http.get(this.BASE_URL + url + '&idUser=' + this.idUser, {responseType: 'json'})
    .pipe(
      tap( (res: any[]) => {
        return res;
        }),
      catchError(this.handleError)
    );
  }

  // create a resource
  post(resource: any, url: string) {
    return this.http.post(this.BASE_URL + url + '&idUser=' + this.idUser, resource, {responseType: 'json'})
    .pipe(
      tap( (res: any) => {
        return res;
        }),
      catchError(this.handleError)
    );
  }

  // delete a resource
  delete(id: any, url: string) {
    return this.http.delete(this.BASE_URL + url + '&id=' + id + '&idUser=' + this.idUser, {responseType: 'json'})
    .pipe(
      catchError(this.handleError)
    );
  }

  // update data
  update(resource: any, url: string) {
    return this.http.put(this.BASE_URL + url + '&idUser=' + this.idUser, resource, {responseType: 'json'})
    .pipe(
      tap( (res: any) => {
        return res;
        }),
      catchError(this.handleError)
    );
  }

  // set headers option
  getHttpOption() {
    const token = this.getToken();
    const httpOption = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: token
      })
    };
    return httpOption;
  }



  getToken() {
    if (localStorage.getItem('token')) {
      return localStorage.getItem('token');
    }
    return null;
  }

  // handler all error
  protected handleError(error: HttpErrorResponse) {

    if (error.status === 404) {
      return throwError(new NotFoundError());
    }

    if (error.status === 400) {
      return throwError(new BadInputError(error));
    }

    if (error.status === 403) {
      return throwError(new ForbidenError());
    }

    if (error.status === 401) {
      return throwError(new UnauthorizedError());
    }

    if (error.status === 500) {
      return throwError(new InternalServerError());
    }

    return throwError(new AppError(error));
  }
}
