import { IDepot } from '../../interfaces/models';
import { Action } from '@ngrx/store';

export const SET = '[Depots] Set';
export const ADD = '[Depots] Add';
export const EDIT = '[Depots] Edit';
export const DELETE = '[Depots] Delete';
export const OPEN_MODAL = '[Depots] Open modal';
export const CLOSE_MODAL = '[Depots] Close modal';

export class Set implements Action {
  readonly type = SET;

  constructor(public data: IDepot[]) {}
}

export class Add implements Action {
  readonly type = ADD;

  constructor(public data: IDepot) {}
}

export class Edit implements Action {
  readonly type = EDIT;

  constructor(public data: IDepot) {}
}

export class Delete implements Action {
  readonly type = DELETE;

  constructor(public id: any) {}
}

export class OpenModal implements Action {
  readonly type = OPEN_MODAL;
}

export class CloseModal implements Action {
  readonly type = CLOSE_MODAL;
}

export type All = Set | Add | Edit | Delete | OpenModal | CloseModal;
