import { IProduit } from './../../../interfaces/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { UserService } from '../user/user.service';
import { Content } from 'src/app/ui/interfaces/modal';

@Component({
  selector: 'app-produit',
  templateUrl: './produit.component.html',
  styleUrls: ['./produit.component.scss']
})
export class ProduitComponent extends BasePageComponent implements OnInit, OnDestroy {

  produits: IProduit[];
  pageForm: FormGroup;
  loading = false;
  change: boolean;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private userS: UserService
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: 'Personne',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'Personne'
        }
      ]
    };
    this.produits = [];
    this.change =  false;
  }

  ngOnInit() {
    super.ngOnInit();

    this.store.select('produits').subscribe(datas => {
      if (datas && datas.length) {
        this.produits = datas;
        !this.pageData.loaded ? this.setLoaded() : null;
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.initForm(row);
    this.modal.open({
      body,
      header,
      footer,
      options: null
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
    if (this.pageForm) {
      this.pageForm.reset();
    }
  }

  // init form
  initForm(data: any) {
    if (!data) {
      this.pageForm = this.fb.group({
        nature: ['', Validators.required],
        code: ['', Validators.required]
      });
    } else {
      this.pageForm = this.fb.group({
        
      });
    }

     // detect form changes
    this.pageForm.valueChanges.subscribe(() => {
      this.change = true;
    });
  }

  getAccess(operation: string) {
    return this.userS.verifieRole(operation);
  }
}
