import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import { AgmCoreModule } from '@agm/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NzDatePickerModule } from 'ng-zorro-antd';

import { environment } from '../../environments/environment';
import { UIModule } from '../ui/ui.module';
import { LayoutModule } from '../layout/layout.module';
import { BasePageComponent } from './base-page';

import { PageDashboardComponent } from './dashboards/dashboard-1';
import { PageInvoiceComponent } from './apps/service-pages/invoice';
import { PagePricingComponent } from './apps/service-pages/pricing';
import { PageTimelineComponent } from './apps/service-pages/timeline';
import { PageUserProfileComponent } from './apps/service-pages/user-profile';
import { PageEditAccountComponent } from './apps/service-pages/edit-account';
import { PageCalendarComponent } from './apps/service-pages/calendar';
import { PageSignInComponent } from './apps/sessions/sign-in';
import { PageSignUpComponent } from './apps/sessions/sign-up';
import { Page404Component } from './apps/sessions/page-404';
import { Page500Component } from './apps/sessions/page-500';
import { DepotComponent } from './gestion/depot/depot.component';
import { UserComponent } from './gestion/user/user.component';
import { ReceptionComponent } from './gestion/reception/reception.component';
import { ReservoirComponent } from './gestion/reservoir/reservoir.component';
import { PersonComponent } from './gestion/person/person.component';
import { VehiculeComponent } from './gestion/vehicule/vehicule.component';
import { DechargementComponent } from './gestion/dechargement/dechargement.component';
import { LivraisonComponent } from './gestion/livraison/livraison.component';
import { BonLivraisonComponent } from './gestion/bon-livraison/bon-livraison.component';
import { TransfertComponent } from './gestion/transfert/transfert.component';
import { BonTransfertComponent } from './gestion/bon-transfert/bon-transfert.component';
import { RetourBonTransfertComponent } from './gestion/retour-bon-transfert/retour-bon-transfert.component';
import { ProduitComponent } from './gestion/produit/produit.component';
import { JaugeageComponent } from './gestion/jaugeage/jaugeage.component';
import { JaugeageReceptionComponent } from './gestion/jaugeage-reception/jaugeage-reception.component';
import { JaugeageTankComponent } from './gestion/jaugeage-tank/jaugeage-tank.component';
import { JaugeageTuyauComponent } from './gestion/jaugeage-tuyau/jaugeage-tuyau.component';
import { ReceptionAttendueComponent } from './gestion/reception-attendue/reception-attendue.component';
import { RapportDechargementComponent } from './gestion/rapport-dechargement/rapport-dechargement.component';
import { ConsommationComponent } from './gestion/consommation/consommation.component';
import { BonConsommationComponent } from './gestion/bon-consommation/bon-consommation.component';
import { TransfertAttenduComponent } from './gestion/transfert-attendu/transfert-attendu.component';
import { CalculatriceComponent } from './gestion/calculatrice/calculatrice.component';
import { TransfertCompteurComponent } from './gestion/transfert-compteur/transfert-compteur.component';
import { TransfertDepotComponent } from './gestion/transfert-depot/transfert-depot.component';
import { HistoriqueTransfertDepotComponent } from './gestion/historique-transfert-depot/historique-transfert-depot.component';
import { JaugeageConteneurComponent } from './gestion/jaugeage-conteneur/jaugeage-conteneur.component';
import { DepotReceptionComponent } from './gestion/depot-reception/depot-reception.component';
import { DepotCompteurComponent } from './gestion/depot-compteur/depot-compteur.component';
import { BtDepotComponent } from './gestion/bt-depot/bt-depot.component';
import { StockComponent } from './gestion/stock/stock.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    ChartsModule,
    NgxChartsModule,
    NgxEchartsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapApiKey
    }),
    LeafletModule,
    FullCalendarModule,
    NzDatePickerModule,
    UIModule,
    LayoutModule
  ],
  declarations: [
    BasePageComponent,
    PageDashboardComponent,
    PageInvoiceComponent,
    PagePricingComponent,
    PageTimelineComponent,
    PageUserProfileComponent,
    PageEditAccountComponent,
    PageCalendarComponent,
    PageSignInComponent,
    PageSignUpComponent,
    Page404Component,
    Page500Component,
    DepotComponent,
    UserComponent,
    ReceptionComponent,
    ReservoirComponent,
    PersonComponent,
    VehiculeComponent,
    DechargementComponent,
    LivraisonComponent,
    BonLivraisonComponent,
    TransfertComponent,
    BonTransfertComponent,
    RetourBonTransfertComponent,
    ProduitComponent,
    JaugeageComponent,
    JaugeageReceptionComponent,
    JaugeageTankComponent,
    JaugeageTuyauComponent,
    ReceptionAttendueComponent,
    RapportDechargementComponent,
    ConsommationComponent,
    BonConsommationComponent,
    TransfertAttenduComponent,
    CalculatriceComponent,
    TransfertCompteurComponent,
    TransfertDepotComponent,
    HistoriqueTransfertDepotComponent,
    JaugeageConteneurComponent,
    DepotReceptionComponent,
    DepotCompteurComponent,
    BtDepotComponent,
    StockComponent
  ],
  exports: [ ],
  entryComponents: [ ]
})
export class PagesModule {}
