import { TransfertService } from './../transfert/transfert.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { Content } from 'src/app/ui/interfaces/modal';
import { CommonService } from 'src/app/services/common/common.service';
import { IOption } from 'src/app/ui/interfaces/option';
import { JaugeageService } from 'src/app/layout/vertical/jaugeage.service';
import * as TransfertActions from '../../../store/actions/transferts.actions';
import * as JaugeageActions from '../../../store/actions/jaugeages.actions';
import * as SjtActions from './../../../store/actions/stocks.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bon-transfert',
  templateUrl: './bon-transfert.component.html',
  styleUrls: ['./bon-transfert.component.scss']
})
export class BonTransfertComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  change: boolean;
  loading = false;
  reservoirs: IOption[];
  reservoir: any = '';
  avant = false;
  apres = false;
  totalAmbiant = 0;
  total15 = 0;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private jaugeS: JaugeageService,
    private comS: CommonService,
    private transfertS: TransfertService,
    private router: Router
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: 'Note de transfert interne',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'bon-transfert'
        }
      ]
    };
    this.change = false;
    this.reservoirs = [];
  }

  ngOnInit() {
    super.ngOnInit();
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    const depotId =  userInfo.depots_id.id;
    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(contenant => {
          if (contenant.depots_id.id === depotId && contenant.type === 'RESERVOIR') {
            const c: IOption = {label: '', value: ''};
            c.label = contenant.code.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
            c.value = contenant.id;
            this.reservoirs.push(c);
            this.reservoir = this.reservoirs[0].value;
          }
        });
      }
    });
    this.initForm();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.modal.open({
      body,
      header,
      footer,
      options : { height: 'auto', width: 600, closeButton: false, overlay: true, overlayClose: true }
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
  }

  // init form
  initForm() {
    this.pageForm = this.fb.group({
      numero: [2000000],
      date_a: [new Date(), Validators.required],
      heure_a: [this.comS.hourString(new Date()), Validators.required],
      tank_depart: [this.reservoir, Validators.required],
      hp_a: [0, Validators.required],
      he_a: [0, Validators.required],
      tm_a: [0, Validators.required],
      da_a: [0],
      ta_a: [0],
      d15_a: [0],
      lp_a: [0],
      leau_a: [0],
      la_a: [0],
      l15_a: [0],
      tmv_a: [0],
      coef_a: [0],
      type: ['TRANSFERT'],
      date_p: [new Date(), Validators.required],
      heure_p: [this.comS.hourString(new Date()), Validators.required],
      tank_destination: [this.reservoir, Validators.required],
      hp_p: [0, Validators.required],
      he_p: [0, Validators.required],
      tm_p: [0, Validators.required],
      da_p: [0],
      ta_p: [0],
      d15_p: [0],
      lp_p: [0],
      leau_p: [0],
      la_p: [0],
      l15_p: [0],
      tmv_p: [0],
      coef_p: [0],
      total_ambiant: [this.totalAmbiant],
      total15: [this.total15],
      observation: ['']
    });

    // detect form changes
    this.pageForm.valueChanges.subscribe(() => {
      this.change = true;
    });
  }

  saveData(form: FormGroup) {
    if (form.valid) {
      if (!(form.value.tank_depart === form.value.tank_destination)) {
        this.loading = true;
        const newData = form.value;
        newData.coef_a = this.jaugeS.coef(newData.d15_a, newData.tm_a);
        newData.coef_p = this.jaugeS.coef(newData.d15_p, newData.tm_p);

        this.transfertS.create(newData)
        .subscribe(
          (data) => {
            console.log('transfert ', data);
            this.loading = false;
            this.messageSuccess('Création');
            this.store.dispatch(new TransfertActions.Add(data.transfert));
            this.store.dispatch(new JaugeageActions.Add(data.avant));
            this.store.dispatch(new JaugeageActions.Add(data.apres));
            this.jaugeS.getData(this.jaugeS.url + '?ctrl=stock', 'stocks', 'setDataStore', SjtActions);
            setTimeout(() => {
              this.router.navigateByUrl('/vertical/transfert');
            }, 4300);
          },
          (error) => {
            this.loading = false;
            this.messageError();
          }
        );
      } else {
        alert('Les deux réservoirs ne doivent pas etre identiques !');
      }
    }
  }

  calculer(form: FormGroup) {
    const newData = form.value;
    if (newData.tank_destination) {
      this.loading = true;
      newData.coef_a = this.jaugeS.coef(newData.d15_a, newData.tm_a);
      newData.coef_p = this.jaugeS.coef(newData.d15_p, newData.tm_p);
  
      this.transfertS.calculer(newData)
      .subscribe(
        (data) => {
          this.loading = false;
          console.log('data : ', data);
          this.totalAmbiant = data.totalAmbiant;
          this.total15 = data.total15;
        },
        (error) => {
          this.loading = false;
          this.messageError();
        }
      );
    } else {
      alert('Veuillez indiquer le réservoir de destination !');
    }
  }
}
