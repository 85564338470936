import { JaugeageService } from './jaugeage.service';
import { UserService } from './../../pages/gestion/user/user.service';
import { IDepot, IJaugeage } from './../../interfaces/models';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { IAppState } from '../../interfaces/app-state';
import { BaseLayoutComponent } from '../base-layout/base-layout.component';
import { HttpService } from '../../services/http/http.service';
import { IOption } from '../../ui/interfaces/option';
import { Content } from '../../ui/interfaces/modal';
import { TCModalService } from '../../ui/services/modal/modal.service';
import * as JaugeagesActions from '../../store/actions/jaugeages.actions';
import * as SettingsActions from '../../store/actions/app-settings.actions';

@Component({
  selector: 'vertical-layout',
  templateUrl: './vertical.component.html',
  styleUrls: [
    '../base-layout/base-layout.component.scss',
    './vertical.component.scss'
  ]
})
export class VerticalLayoutComponent extends BaseLayoutComponent implements OnInit {
  jaugeageForm: FormGroup;
  typeJauge: IOption[];
  reservoirs: IOption[];
  depot: IDepot;
  jaugeage: IJaugeage;
  date: Date;
  loading = false;

  constructor(
    store: Store<IAppState>,
    fb: FormBuilder,
    httpSv: HttpService,
    router: Router,
    elRef: ElementRef,
    private modal: TCModalService,
    private userS: UserService,
    private jaugeS: JaugeageService
  ) {
    super(store, fb, httpSv, router, elRef);
    this.reservoirs = [];
    this.depot = {};
    this.jaugeage = {}; this.date = new Date();
    this.jaugeage.coef = 0.0; this.jaugeage.observation = '';
    this.jaugeage.d15 = 0; this.jaugeage.da = 0; this.jaugeage.date = '';
    this.jaugeage.he = 0; this.jaugeage.hp = 0;
    this.jaugeage.l15 = 0; this.jaugeage.la = 0; this.jaugeage.leau = 0;
    this.jaugeage.ta = 0; this.jaugeage.tm = 0; this.jaugeage.tmv = 0;
    this.jaugeage.reservoir = {};
  }

  ngOnInit() {
    super.ngOnInit();

    this.store.dispatch(new SettingsActions.Update({ layout: 'vertical' }));
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    const depotId =  userInfo.depots_id.id;
    this.depot = userInfo.depots_id;
    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(contenant => {
          if (contenant.depots_id.id === depotId && contenant.type === 'RESERVOIR') {
            const c: IOption = {label: '', value: ''};
            c.label = contenant.code.toUpperCase()
                      + ' - Capacité: ' + contenant.capacite + 'M3'
                      + ' - [' + contenant.produits_id.code.toUpperCase() + ']';
            c.value = contenant.id;
            this.reservoirs.push(c);
          }
        });
      }
    });
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, options: any = null) {
    // this.initjaugeageForm();

    this.modal.open({
      body,
      header,
      footer,
      options
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
  }

  // init form
  initjaugeageForm() {}

  // add new patient
  addJaugeage() {
    this.loading = true;
    this.jaugeage.type = 'stockage';
    this.jaugeage.date = this.date.getFullYear() + '-' + this.date.getMonth() + '-' + this.date.getDate()
                        + ' ' + this.date.getHours() + ':' + this.date.getMinutes() + ':' + this.date.getSeconds();
    
    console.log('jaugeage', this.jaugeage);

    this.jaugeS.create(this.jaugeage)
    .subscribe(
      (data) => {
        this.loading = false;
        alert('Jaugeage enregistré avec succès !');
        this.store.dispatch(new JaugeagesActions.Add(data));
      },
      (error) => {
        this.loading = false;
        alert('Erreur lors de l\'enregistrement !');
      }
    );
  }

  getAccess(operation: string) {
    return this.userS.verifieRole(operation);
  }

  calculer() {
    if (this.jaugeage.reservoir.id) {
      this.jaugeage.d15 = this.jaugeS.densite15(this.jaugeage.da, this.jaugeage.ta);
    } else {
      alert('Choisissez un réservoir svp !');
    }
  }
}
