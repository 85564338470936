import { UserService } from './../../../gestion/user/user.service';
import { EditAccountService } from './edit-account.service';
import { IDepot } from './../../../../interfaces/models';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../../base-page';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../interfaces/app-state';
import { HttpService } from '../../../../services/http/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as UserActions from './../../../../store/actions/users.actions';
import { AppError } from 'src/app/services/errors/app-error';
import { ForbidenError } from 'src/app/services/errors/forbiden-error';
import { NotFoundError } from 'src/app/services/errors/not-found-error';

interface IChangePassword {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

@Component({
  selector: 'page-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss']
})
export class PageEditAccountComponent extends BasePageComponent implements OnInit, OnDestroy {
  userInfo: any;
  userForm: FormGroup;
  changes: boolean;
  changesUser: boolean;
  depots: IDepot[];
  passwordForm: FormGroup;
  isNotValid = false;
  view = 'error';
  loading = false;
  loadingForm = false;
  errorMessage = '';
  isUserNotValid = false;
  viewUser = 'error';

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    private formBuilder: FormBuilder,
    private editService: EditAccountService,
    private userService: UserService
  ) {
    super(store, httpSv);

    this.pageData = {
      title: 'Changer ses identifiants',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Apps',
          route: 'default-dashboard'
        },
        {
          title: 'Service pages',
          route: 'default-dashboard'
        },
        {
          title: 'Editer son compte'
        }
      ]
    };
    this.changes = false;
    this.changesUser = false;
    this.depots = [];
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadedDetect();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  loadedDetect() {
    this.userInfo = JSON.parse(localStorage.getItem('myUser'));
    this.depots = this.userInfo.depots_id;
    this.inituserForm(this.userInfo);
  }

  // init form
  inituserForm(data: any) {
    this.userForm = this.formBuilder.group({
      id: [data.id, Validators.required],
      pseudo: [data.pseudo, Validators.required],
      nom: [data.nom, Validators.required],
      niveau: [data.niveau, Validators.required],
      depot: [data.depots_id.nom || '' + ' / ' + data.depots_id.localite || '', Validators.required]
    });

    this.passwordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });

    // detect form changes
    this.passwordForm.valueChanges.subscribe(() => {
      this.changesUser = true;
    });

    // detect form changes
    this.userForm.valueChanges.subscribe(() => {
      this.changes = true;
    });
  }

  // save form data
  saveData(form: FormGroup) {
    if (form.valid) {
      this.editService.postPseudo(form.value)
      .subscribe(
        (data) => {
          if (data) {
            this.loading = false;
            this.viewUser = 'success';
            this.isUserNotValid = true;
            this.errorMessage = 'Enregistrement effectué avec succès !';
            this.timeOut();
            localStorage.removeItem('myUser');
            localStorage.setItem('myUser', JSON.stringify(data));
            this.store.dispatch(new UserActions.Edit(data));
            this.changes = false;
          }
        },
        (error) => {
          this.errorUpdate();
          console.log(error);
        }
      );
    }
  }

  onChangePassword(form: FormGroup) {
    if (form.valid) {
      const data: IChangePassword = form.value;
      if (!(data.newPassword === data.confirmPassword)) {

        this.isNotValid = true;
        this.errorMessage = 'Les deux mots de passe ne sont pas identiques';
        this.timeOut();

      } else {
        this.loadingForm = true;
        this.userService.changePassword(data)
        .subscribe(
          () => {
              this.view = 'success';
              this.loadingForm = false;
              this.isNotValid = true;
              this.errorMessage = 'Changement effectué avec succès ';
              this.timeOut();
          },
          (error: AppError) => {
            this.loadingForm = false;
            this.isNotValid = true;

            if ((error instanceof ForbidenError) || (error instanceof NotFoundError)) {
              this.errorMessage = 'Mot de passe courant non reconnu !';
              this.timeOut();
            } else {
              this.errorMessage = 'Erreur : Problème de connexion !';
              this.timeOut();
            }
          }
        );
      }

    } else {
      this.changesUser = false;
    }
    this.passwordForm.reset();
  }

  timeOut() {
    setTimeout(() => {
      this.isNotValid = false;
      this.isUserNotValid = false;
    }, 4000);
  }

  errorUpdate() {
    this.loading = false;
    this.viewUser = 'error';
    this.isUserNotValid = true;
    this.errorMessage = 'Une erreur est survenue lors de l\'enregistrement !';
    this.timeOut();
  }
}
