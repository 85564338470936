import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { throwError as observableThrowError, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotFoundError } from '../errors/not-found-error';
import { BadInputError } from '../errors/bad-input-error';
import { ForbidenError } from '../errors/forbiden-error';
import { UnauthorizedError } from '../errors/unauthorized-error';
import { InternalServerError } from '../errors/InternalServerError';
import { AppError } from '../errors/app-error';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  protected readonly BASE_URL = 'https://kuntualagestionpetro.bbhsarlu.com/gestiontankphp/';

  constructor(private http: HttpClient) {}

  getData(source: string) {
    return this.http.get(source)
    .pipe(
      tap((res: any) => res),
      catchError(this.handleError)
    );
  }

  getOne(id: string, url: string) {
    return this.http.get(this.BASE_URL + url + '&id=' + id, {responseType: 'json'})
    .pipe(
      tap( (res: any) => {
        return res;
        }),
      catchError(this.handleError)
    );
  }

  // get all data in server
  getAll(url: string) {
    return this.http.get(this.BASE_URL + url, {responseType: 'json'})
    .pipe(
      tap( (res: any[]) => {
        return res;
        }),
      catchError(this.handleError)
    );
  }

  // create a resource
  post(resource: any, url: string) {
    return this.http.post(this.BASE_URL + url, resource, {responseType: 'json'})
    .pipe(
      tap( (res: any) => {
        return res;
        }),
      catchError(this.handleError)
    );
  }

  // delete a resource
  delete(id: string, url: string) {
    return this.http.delete(this.BASE_URL + url + id, {responseType: 'json'})
    .pipe(
      catchError(this.handleError)
    );
  }

  // update data
  update(resource: any, url: string) {
    return this.http.put(this.BASE_URL + url, resource, {responseType: 'json'})
    .pipe(
      tap( (res: any) => {
        return res;
        }),
      catchError(this.handleError)
    );
  }

  protected handleError(error: HttpErrorResponse) {

    if (error.status === 404) {
      return throwError(new NotFoundError());
    }

    if (error.status === 400) {
      return throwError(new BadInputError(error));
    }

    if (error.status === 403) {
      return throwError(new ForbidenError());
    }

    if (error.status === 401) {
      return throwError(new UnauthorizedError());
    }

    if (error.status === 500) {
      return throwError(new InternalServerError());
    }

    return throwError(new AppError(error));
  }
}
