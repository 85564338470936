import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { UserService } from '../user/user.service';
import { Content } from 'src/app/ui/interfaces/modal';

@Component({
  selector: 'app-vehicule',
  templateUrl: './vehicule.component.html',
  styleUrls: ['./vehicule.component.scss']
})
export class VehiculeComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  loading = false;
  change: boolean;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private userS: UserService
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: 'Véhicule',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'vehicule'
        }
      ]
    };
  }

  ngOnInit() {
    super.ngOnInit();

    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        !this.pageData.loaded ? this.setLoaded() : null;
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.initForm(row);
    this.modal.open({
      body,
      header,
      footer,
      options: null
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
    if (this.pageForm) {
      this.pageForm.reset();
    }
  }

  // init form
  initForm(data: any) {
    if (!data) {
      this.pageForm = this.fb.group({
        
      });
    } else {
      this.pageForm = this.fb.group({
        
      });
    }

     // detect form changes
    this.pageForm.valueChanges.subscribe(() => {
      this.change = true;
    });
  }
}
