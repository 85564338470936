import { IStocks } from '../../interfaces/models';
import { Action } from '@ngrx/store';

export const SET = '[SJT] Set';
export const ADD = '[SJT] Add';
export const EDIT = '[SJT] Edit';
export const DELETE = '[SJT] Delete';
export const OPEN_MODAL = '[SJT] Open modal';
export const CLOSE_MODAL = '[SJT] Close modal';

export class Set implements Action {
  readonly type = SET;

  constructor(public data: IStocks[]) {}
}

export class Add implements Action {
  readonly type = ADD;

  constructor(public data: IStocks) {}
}

export class Edit implements Action {
  readonly type = EDIT;

  constructor(public data: IStocks) {}
}

export class Delete implements Action {
  readonly type = DELETE;

  constructor(public id: any) {}
}

export class OpenModal implements Action {
  readonly type = OPEN_MODAL;
}

export class CloseModal implements Action {
  readonly type = CLOSE_MODAL;
}

export type All = Set | Add | Edit | Delete | OpenModal | CloseModal;
