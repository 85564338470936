import { IGestion } from './../../interfaces/models';
import * as GestionsActions from '../actions/gestions.actions';

export type Action = GestionsActions.All;

// Reducer function
export function gestionsReducer(data: IGestion[] = [], action: Action) {
  switch (action.type) {
    case GestionsActions.SET: {
      return action.data;
    }
    case GestionsActions.EDIT: {
      data.forEach((depot: IGestion, index) => {
        if (depot.id === action.data.id) {
          data[index] = action.data;
        }
      });

      return [...data];
    }
    case GestionsActions.ADD: {
      return [action.data, ...data];
    }
    case GestionsActions.DELETE: {
      const newData = data.filter((depot: IGestion) => {
        return depot.id !== action.id;
      });

      return newData;
    }
    case GestionsActions.OPEN_MODAL: {
      return true;
    }
    case GestionsActions.CLOSE_MODAL: {
      return false;
    }
    default: {
      return data;
    }
  }
}
