import { IReception } from '../../interfaces/models';
import { Action } from '@ngrx/store';

export const SET = '[Receptions] Set';
export const ADD = '[Receptions] Add';
export const EDIT = '[Receptions] Edit';
export const DELETE = '[Receptions] Delete';
export const OPEN_MODAL = '[Receptions] Open modal';
export const CLOSE_MODAL = '[Receptions] Close modal';

export class Set implements Action {
  readonly type = SET;

  constructor(public data: IReception[]) {}
}

export class Add implements Action {
  readonly type = ADD;

  constructor(public data: IReception) {}
}

export class Edit implements Action {
  readonly type = EDIT;

  constructor(public data: IReception) {}
}

export class Delete implements Action {
  readonly type = DELETE;

  constructor(public id: any) {}
}

export class OpenModal implements Action {
  readonly type = OPEN_MODAL;
}

export class CloseModal implements Action {
  readonly type = CLOSE_MODAL;
}

export type All = Set | Add | Edit | Delete | OpenModal | CloseModal;
