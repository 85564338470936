import { JaugeageService } from 'src/app/layout/vertical/jaugeage.service';
import { CommonService } from './../../../services/common/common.service';
import { IOption } from './../../../ui/interfaces/option';
import { IStocks, Niveau } from './../../../interfaces/models';
import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { FormBuilder } from '@angular/forms';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'page-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class PageDashboardComponent extends BasePageComponent implements OnInit, OnDestroy {

  depotuser: string;
  loading = false;
  userInfo: any;
  dateSjr: Date;
  reservoirs: IOption[];
  reservoir: IOption;
  depotId: any;
  sjrs: IStocks[];
  dateDebut: Date;
  dateFin: Date;
  date = new Date();
  opt = { year: 'numeric', month: 'long', day: 'numeric' };
  depotcheck = true;

  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    private fb: FormBuilder,
    private comS?: CommonService,
    private jaugeageS?: JaugeageService
  ) {
    super(store, httpSv);

    this.pageData = {
      title: '',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Dashboards'
        }
      ]
    };
    this.reservoirs = [];
    this.reservoir = {label: '', value: ''};;
    this.depotuser = '';
    this.userInfo = {};
    this.depotId = null;
    this.sjrs = [];
    this.dateDebut = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    this.dateFin = new Date();
    this.initData();
  }

  initData() {
    const data: IStocks = {
      date: null
    };
    this.sjrs[0] = data;
  }

  ngOnInit() {
    super.ngOnInit();

    this.userInfo = JSON.parse(localStorage.getItem('myUser'));
    if (this.userInfo.depots_id) {
      this.depotuser = this.userInfo.depots_id.nom.toUpperCase();
    }
    this.depotId =  this.userInfo.depots_id.id;
    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(contenant => {
          if (contenant.depots_id.id === this.depotId && contenant.type !== 'CONTENEUR') {
            const c: IOption = {label: '', value: ''};
            c.label = contenant.code.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
            c.value = contenant.id;
            this.reservoirs.push(c);
          } else {
            if ((this.userInfo.niveau === Niveau.admin) && (contenant.type !== 'CONTENEUR')) {
              const c: IOption = {label: '', value: ''};
              c.label = contenant.code.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
              c.value = contenant.id;
              this.reservoirs.push(c);
            } else {
              if ((this.userInfo.niveau === Niveau.superAdministrateur) && (contenant.type !== 'CONTENEUR')) {
                const c: IOption = {label: '', value: ''};
                c.label = contenant.code.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
                c.value = contenant.id;
                this.reservoirs.push(c);
              }
            }
          }
        });
        this.reservoir = this.reservoirs[0];
        this.voirSJR();
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  voirSJR() {
    if (this.reservoir.value) {
      this.sjrs = [];
      this.initData();
      const dateDebut = this.comS.dateString(this.dateDebut);
      const dateFin = this.comS.dateString(this.dateFin);

      this.store.select('stocks').subscribe(datas => {
        if (datas && datas.length) {
          const total: IStocks = {
            l15recp: 0,
            bl: 0,
            consm: 0,
            bt: 0,
            bti: 0,
            totalsortie: 0,
            totalprodtheor: 'TOTAL',
            gain: 0,
            perte: 0
          };

          const ecart: IStocks = {
            totalprodtheor: 'ECART',
            gain: 0
          };
          datas.forEach(sjr => {
            if ((sjr.reservoir === this.reservoir.value) &&
                (this.comS.compareDate(dateDebut, dateFin, sjr.date))) {
              if (this.sjrs[0].date === null) {
                this.sjrs.pop();
              }
              this.sjrs.push(sjr);
              const index = this.sjrs.lastIndexOf(sjr);
              if (index !== -1) {
                if (index !== 0) {
                  const valeur = sjr.l15 - this.sjrs[index - 1].totalprodtheor;
                  if (valeur < 0) {
                    this.sjrs[index - 1].perte = valeur;
                  } else {
                    this.sjrs[index - 1].gain = valeur;
                  }
                }
              }
              total.l15recp += sjr.l15recp;
              total.bl += sjr.bl;
              total.consm += sjr.consm;
              total.bt += sjr.bt;
              total.bti += sjr.bti;
              total.totalsortie += sjr.totalsortie;
              if (index !== 0) {
                total.gain += this.sjrs[index - 1].gain;
                total.perte += this.sjrs[index - 1].perte;
              }
            }
          });

          if (this.sjrs[0].date) {
            this.sjrs.push(total);
            ecart.gain = total.gain + total.perte;
            this.sjrs.push(ecart);
          }
        }
      });
    } else { // y a pas des reservoirs, on sort
      alert('Veuillez choisir un reservoir svp !');
    }
  }

  creerPDF() {
    const data = document.getElementById('pdfTable');
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('l', 'cm', 'a4'); // Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'JPEG', 0, 0, 29.7, 21.0);
      pdf.save('Filename.pdf');
    });

  }

}
