import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService } from '../../../services/http/http.service';

@Component({
  selector: 'actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  notifications: any[];
  messages: any[];
  files: any[];
  closeDropdown: EventEmitter<boolean>;
  @Input() layout: string;
  nom = '';
  role = '';

  constructor(
    private httpSv: HttpService,
    private router: Router
  ) {
    this.notifications = [];
    this.messages = [];
    this.files = [];
    this.closeDropdown = new EventEmitter<boolean>();
    this.layout = 'vertical';
  }

  ngOnInit() {
    this.getData('assets/data/navbar-notifications.json', 'notifications');
    
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    this.nom = userInfo.nom.toUpperCase();
    this.role = userInfo.niveau;


  }

  getData(url: string, dataName: string) {
    this.httpSv.getData(url).subscribe(
      data => {
        this[dataName] = data;
      },
      err => {
        console.log(err);
      }
    );
  }

  onCloseDropdown() {
    this.closeDropdown.emit(true);
  }

  goTo(event: Event, link: string, layout: string = '') {
    event.preventDefault();

    this.onCloseDropdown();
    setTimeout(() => {
      if (link === 'sign-in') {
        localStorage.clear();
      }
      this.router.navigate([layout ? layout : this.layout, link]);
    });
  }
}
