import { IJaugeage } from './../../interfaces/models';
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/http/data.service';
import { HttpClient } from '@angular/common/http';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class JaugeageService extends DataService {
  url = 'https://kuntualagestionpetro.bbhsarlu.com/gestiontankphp/';
  constructor(
    http: HttpClient,
    private store: Store<IAppState>,
    private httpSv: HttpService
  ) {
    super(http);
  }

  create(data: IJaugeage) {
    return this.post(data, '?ctrl=jaugeage&action=creer');
  }

  createPDF() {
    return this.getAll('?ctrl=pdf');
  }
  supprimer(id: any) {
    return this.delete(id, '?ctrl=jaugeage&action=supprimer');
  }

  updateJauge(data: IJaugeage) {
    return this.update(data, '?ctrl=jaugeage&action=modifier');
  }

  getData(url: string, dataName: string, callbackFnName?: string, action?: any) {
    this.httpSv.getData(url).subscribe(
      data => {
        this[dataName] = data;
      },
      err => {
        console.log(err);
      },
      () => {
        (callbackFnName && typeof this[callbackFnName] === 'function') ? this[callbackFnName](this[dataName], action) : null;
      }
    );
  }

  setDataStore(data: any[] = [], action: any) {
    this.store.dispatch(new action.Set(data));
  }

  densite15(D: number, T: number) {
    let rho15 = 0;
    let delta = 0;
    let hyc = 0;
    const k0: number[] = [346.4228, 594.5418, 186.9696, -0.00336312];
    const k1: number[] = [0.4388, 0, 0.4862, 2680.3206];
    const tfc: number[] = [0, 0, 0, 0];
    const vfc: number[] = [0, 0, 0, 0];
    const rho: number[] = [0, 0, 0, 0];

    const A = -0.00336312;
    const B = 2680.3206;
    const C = 778.84;

    const Rho = this.round(D / 5, 1) * 5;
    T = this.round(T * 2, 1) / 2;
    delta = T - 15;
    hyc = 1 - this.round(0.000023 * delta, 9) - this.round(0.00000002 * 0.00000002 * (delta * delta), 9);
    const rhoT = this.trunc(Rho * hyc, 2);

    for (let i = 0; i < 4; i++) {
      if (i !== 3) {
        tfc[i] = this.round((k0[i] / Math.pow(rhoT, 2)) + (k1[i] / rhoT), 7);
      } else {
        tfc[i] = this.round(k0[i] + (k1[i] / (C * C)), 7);
      }

      vfc[i] = this.round(Math.exp(-tfc[i] * delta - this.round(0.8 * Math.pow((tfc[i] * delta), 2), 9)), 6);
      rho[i] = this.round(this.trunc(rhoT / vfc[i], 3), 2);
    }

    for (let i = 0; i < 4; i++) {
      let a = 0;
      do {
        a = rho[i];
        if (i !== 3) {
          tfc[i] = this.round((k0[i] / Math.pow(rho[i], 2)) + (k1[i] / rho[i]), 7);
        } else {
          tfc[i] = this.round(k0[i] + (k1[i] / (rho[i] * rho[i])), 7);
        }

        vfc[i] = this.round(Math.exp(-tfc[i] * delta - this.round(0.8 * Math.pow((tfc[i] * delta), 2), 9)), 6);
        rho[i] = this.round(this.trunc(rhoT / vfc[i], 3), 2);
      } while (Math.abs(rho[i] - a) > 0.05);
      console.log('rho1', a);
    }

    if (rho[3] > 770.5 && rho[3] < 787.5) {
      rho15 = this.round(rho[3], 1);
    } else {
      if (rho[0] <= 770) {
        rho15 = this.round(rho[0], 1);
      } else {
        if (rho[1] <= 839) {
          rho15 = this.round(rho[1], 1);
        } else {
          rho15 = this.round(rho[2], 1);
        }
      }
    }
    return rho15;
  }

  coef(Rho15c: number, T: number) {
    let VCF0 = 0;
    let d = 0;
    let k0 = 0;
    let k1 = 0;
    const A = -0.00336312;
    const B = 2680.3206;

    const TFC: number[] = [0, 0];
    const VCF: number[] = [0, 0];

    Rho15c = this.round(Rho15c / 5, 1) * 5;
    T = this.round(T * 2, 1) / 2;
    d = T - 15;

    // DÈtermination de k0
    if ( Rho15c > 788) {
      if (Rho15c > 839) {
        k0 = 186.9696;
        k1 = 0.4862;
      } else {
            k0 = 594.5418;
            k1 = 0.0;
      }
    } else {
      k0 = 346.4228;
      k1 = 0.4388;
    }

    // DÈtermination du coÈfficient d'expansion thermique TFC
    for (let i = 0; i < 2; i++) {
      // tslint:disable-next-line:max-line-length
      TFC[0] = this.round(this.trunc(this.trunc( k0 / (Rho15c * Rho15c), 8), 10) + this.trunc(k1 / Rho15c, 10), 7);

      TFC[1] = A + this.round(( this.trunc(B / Rho15c, 6)) / Rho15c, 8);
      TFC[1] = this.round(TFC[1], 7);
    }
    
    for (let i = 0; i < 2; i++) {
      // tslint:disable-next-line:max-line-length
      VCF[i] = this.round(Math.exp(-TFC[i] * d - this.round(0.8 * Math.pow((TFC[i] * d), 2), 9)), 6);
    }

    if (Rho15c > 770 && Rho15c < 788) {
      VCF0 = VCF[1];
    } else {
      VCF0 = VCF[0];
    }

    VCF0 = this.round(VCF0, 4);
    return VCF0;
  }

  round(nombre: number, precision: number) {
    precision = precision;
    const tmp = Math.pow(10, precision);
    return Math.round( nombre * tmp ) / tmp;
  }

  trunc(nombre: number, precision: number) {
    precision = precision;
    const tmp = Math.pow(10, precision);
    return Math.trunc( nombre * tmp ) / tmp;
  }

  isJaugeEgale(jauge: IJaugeage, arrayJauge: IJaugeage[]) {
    for (const ob of arrayJauge) {
      if (ob.idreservoir === jauge.reservoir) {
        if (ob.date === jauge.date && ob.heure === jauge.heure) {
          if (ob.hp === +jauge.hp && ob.he === +jauge.he ) {
            if (ob.tm === +jauge.tm && ob.ta === +jauge.ta ) {
              if (ob.da == jauge.da) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }
}
