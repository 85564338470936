import { StockComponent } from './../pages/gestion/stock/stock.component';
import { BtDepotComponent } from './../pages/gestion/bt-depot/bt-depot.component';
import { DepotReceptionComponent } from './../pages/gestion/depot-reception/depot-reception.component';
import { TransfertAttenduComponent } from './../pages/gestion/transfert-attendu/transfert-attendu.component';
import { BonConsommationComponent } from './../pages/gestion/bon-consommation/bon-consommation.component';
import { ConsommationComponent } from './../pages/gestion/consommation/consommation.component';
import { RapportDechargementComponent } from './../pages/gestion/rapport-dechargement/rapport-dechargement.component';
import { RetourBonTransfertComponent } from './../pages/gestion/retour-bon-transfert/retour-bon-transfert.component';
import { BonTransfertComponent } from './../pages/gestion/bon-transfert/bon-transfert.component';
import { ReceptionAttendueComponent } from './../pages/gestion/reception-attendue/reception-attendue.component';
import { JaugeageTankComponent } from './../pages/gestion/jaugeage-tank/jaugeage-tank.component';
import { JaugeageComponent } from './../pages/gestion/jaugeage/jaugeage.component';
import { ProduitComponent } from './../pages/gestion/produit/produit.component';
import { VehiculeComponent } from './../pages/gestion/vehicule/vehicule.component';
import { PersonComponent } from './../pages/gestion/person/person.component';
import { BonLivraisonComponent } from './../pages/gestion/bon-livraison/bon-livraison.component';
import { DechargementComponent } from './../pages/gestion/dechargement/dechargement.component';
import { ReservoirComponent } from './../pages/gestion/reservoir/reservoir.component';
import { ReceptionComponent } from './../pages/gestion/reception/reception.component';
import { DepotComponent } from './../pages/gestion/depot/depot.component';
import { UserComponent } from './../pages/gestion/user/user.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VerticalLayoutComponent } from '../layout/vertical';
import { HorizontalLayoutComponent } from '../layout/horizontal';
import { PublicLayoutComponent } from '../layout/public';

import { PageDashboardComponent } from '../pages/dashboards/dashboard-1';
import { PageUserProfileComponent } from '../pages/apps/service-pages/user-profile';
import { PageEditAccountComponent } from '../pages/apps/service-pages/edit-account';
import { PageSignInComponent } from '../pages/apps/sessions/sign-in';
import { PageSignUpComponent } from '../pages/apps/sessions/sign-up';
import { Page404Component } from '../pages/apps/sessions/page-404';
import { Page500Component } from '../pages/apps/sessions/page-500';
import { LivraisonComponent } from '../pages/gestion/livraison/livraison.component';
import { JaugeageTuyauComponent } from '../pages/gestion/jaugeage-tuyau/jaugeage-tuyau.component';
import { TransfertComponent } from '../pages/gestion/transfert/transfert.component';
import { JaugeageReceptionComponent } from '../pages/gestion/jaugeage-reception/jaugeage-reception.component';
import { CalculatriceComponent } from '../pages/gestion/calculatrice/calculatrice.component';
import { TransfertCompteurComponent } from '../pages/gestion/transfert-compteur/transfert-compteur.component';
import { TransfertDepotComponent } from '../pages/gestion/transfert-depot/transfert-depot.component';
import { HistoriqueTransfertDepotComponent } from '../pages/gestion/historique-transfert-depot/historique-transfert-depot.component';
import { JaugeageConteneurComponent } from '../pages/gestion/jaugeage-conteneur/jaugeage-conteneur.component';
import { DepotCompteurComponent } from '../pages/gestion/depot-compteur/depot-compteur.component';
import { AuthGuardService } from '../services/common/auth-guard.service';

const VERTICAL_ROUTES: Routes = [
  { path: 'default-dashboard', component: PageDashboardComponent, canActivate: [AuthGuardService] },
  { path: 'user', component: UserComponent, canActivate: [AuthGuardService] },
  { path: 'depot', component: DepotComponent, canActivate: [AuthGuardService] },
  { path: 'reception', component: ReceptionComponent, canActivate: [AuthGuardService] },
  { path: 'person', component: PersonComponent, canActivate: [AuthGuardService] },
  { path: 'produit', component: ProduitComponent, canActivate: [AuthGuardService] },
  { path: 'vehicule', component: VehiculeComponent, canActivate: [AuthGuardService] },

  { path: 'reservoir', component: ReservoirComponent, canActivate: [AuthGuardService] },
  { path: 'dechargement', component: DechargementComponent, canActivate: [AuthGuardService] },
  { path: 'livraison', component: LivraisonComponent, canActivate: [AuthGuardService] },
  { path: 'bon-livraison', component: BonLivraisonComponent, canActivate: [AuthGuardService] },
  { path: 'consommation', component: ConsommationComponent, canActivate: [AuthGuardService] },
  { path: 'bon-consommation', component: BonConsommationComponent, canActivate: [AuthGuardService] },
  { path: 'jaugeage', component: JaugeageComponent, canActivate: [AuthGuardService] },
  { path: 'jaugeage-tank', component: JaugeageTankComponent, canActivate: [AuthGuardService] },
  { path: 'jaugeage-tuyau', component: JaugeageTuyauComponent, canActivate: [AuthGuardService] },
  { path: 'jaugeage-conteneur', component: JaugeageConteneurComponent, canActivate: [AuthGuardService] },
  { path: 'jaugeage-reception/:numero', component: JaugeageReceptionComponent, canActivate: [AuthGuardService] },
  { path: 'depot-reception/:numero', component: DepotReceptionComponent, canActivate: [AuthGuardService] },
  { path: 'depot-compteur/:numero', component: DepotCompteurComponent, canActivate: [AuthGuardService] },
  { path: 'rapport-dechargement/:numero', component: RapportDechargementComponent, canActivate: [AuthGuardService] },
  { path: 'transfert', component: TransfertComponent, canActivate: [AuthGuardService] },
  { path: 'bon-transfert', component: BonTransfertComponent, canActivate: [AuthGuardService] },
  { path: 'retour-bon-transfert', component: RetourBonTransfertComponent, canActivate: [AuthGuardService] },
  { path: 'reception-attendue', component: ReceptionAttendueComponent, canActivate: [AuthGuardService] },
  { path: 'transfert-attendu', component: TransfertAttenduComponent, canActivate: [AuthGuardService] },
  { path: 'transfert-compteur', component: TransfertCompteurComponent, canActivate: [AuthGuardService] },
  { path: 'transfert-depot', component: TransfertDepotComponent, canActivate: [AuthGuardService] },
  { path: 'historique-transfert-depot', component: HistoriqueTransfertDepotComponent, canActivate: [AuthGuardService] },
  { path: 'calculatrice', component: CalculatriceComponent, canActivate: [AuthGuardService] },
  { path: 'bt-depot', component: BtDepotComponent, canActivate: [AuthGuardService] },
  { path: 'stock', component: StockComponent, canActivate: [AuthGuardService] },

  { path: 'user-profile', component: PageUserProfileComponent, canActivate: [AuthGuardService] },
  { path: 'edit-account', component: PageEditAccountComponent, canActivate: [AuthGuardService] }
];

const PUBLIC_ROUTES: Routes = [
  { path: 'sign-in', component: PageSignInComponent },
  { path: 'sign-up', component: PageSignUpComponent },
  { path: 'page-404', component: Page404Component },
  { path: 'page-500', component: Page500Component },
  { path: '**', component: Page404Component }
];

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/public/sign-in',
    pathMatch: 'full'
  },
  {
    path: 'vertical',
    component: VerticalLayoutComponent,
    children: VERTICAL_ROUTES
  },
  {
    path: 'horizontal',
    component: HorizontalLayoutComponent,
    children: VERTICAL_ROUTES
  },
  {
    path: 'public',
    component: PublicLayoutComponent,
    children: PUBLIC_ROUTES
  },
  {
    path: '**',
    component: PublicLayoutComponent,
    children: PUBLIC_ROUTES
  }
];

@NgModule({
  imports: [

  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class RoutingModule { }
