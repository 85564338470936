import { IUserApp, Niveau, Etat, IDepot } from './../../../interfaces/models';
import { IOption } from './../../../ui/interfaces/option';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Content } from 'src/app/ui/interfaces/modal';
import { UserService } from './user.service';
import * as UserActions from '../../../store/actions/users.actions';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends BasePageComponent implements OnInit, OnDestroy {

  users: IUserApp[];
  niveauUser: IOption[];
  etatUser: IOption[];
  userForm: FormGroup;
  disabled = false;
  change: boolean;
  depots: IDepot[];

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private userS: UserService
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: 'Utilisateurs',
      loaded: true,
      breadcrumbs: [
        {
          title: 'SJR',
          route: 'default-dashboard'
        },
        {
          title: 'Utilisateur'
        }
      ]
    };

    this.niveauUser = [
      {
        label: 'Super admin',
        value: Niveau.superAdministrateur
      },
      {
        label: 'Admin Dépots',
        value: Niveau.admin
      },
      {
        label: 'Responsable Dépot',
        value:  Niveau.responsable
      },
      {
        label: 'Opérateur',
        value: Niveau.operateur
      }
    ];

    this.etatUser = [
      {
        label: 'Actif',
        value: Etat.actif
      },
      {
        label: 'Inactif',
        value: Etat.inactif
      }
    ];
    this.users = [];
    this.change = false;
    this.depots = [];
    this.initData();
  }

  initData() {
    const data: IUserApp = { id: null};
    this.users.push(data);
  }

  ngOnInit() {
    super.ngOnInit();
    this.store.select('users').subscribe(users => {
      if (users && users.length) {
        if (!this.users[0].id) {
          this.users.pop();
        }
        this.users = users;
        !this.pageData.loaded ? this.setLoaded(200) : null;
      }
    });

    this.store.select('depots').subscribe(datas => {
      if (datas && datas.length) {
       this.depots = datas;
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.initForm(row);
    this.modal.open({
      body,
      header,
      footer,
      options: null
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
    if (this.userForm) {
      this.userForm.reset();
    }
  }

  initForm(data: IUserApp) {
    if (!data) {
      this.userForm = this.fb.group({
        pseudo: ['', Validators.required],
        nom: ['', Validators.required],
        password: ['abcd1234', Validators.required],
        niveau: [Niveau.operateur, Validators.required],
        etat: [Etat.actif, Validators.required],
        depots_id: [this.depots[0].nom]
      });
    } else {
      this.disabled = false;
      if (data.niveau === Niveau.superAdministrateur) {
        this.disabled = true;
      }
      this.userForm = this.fb.group({
        niveau: [data.niveau, Validators.required],
        etat: [data.etat, Validators.required],
        depots_id: [data.depots_id.nom],
        id: [data.id]
      });
    }

    // detect form changes
    this.userForm.valueChanges.subscribe(() => {
      this.change = true;
    });
  }

  onCreateUser(form: FormGroup) {
    if (form.valid) {
      const newUser: IUserApp = form.value;
      const depot = this.depots.find( d => d.nom === form.value.depots_id);
      newUser.depots_id = depot.id;

      this.userS.create(newUser)
      .subscribe(
        (data) => {
          if (data) {
            this.messageSuccess('Création');
            this.store.dispatch(new UserActions.Add(data));
          }
        },
        (error) => {
          this.messageError();
          console.log('ERREUR DE CREATION DE USER', error);
        }
      );

      this.closeModal();
    }
  }

  onRemove() {
    this.closeModal();
    if (this.idDelete) {
      this.remove(this.idDelete);
      console.log('Remove success !', this.idDelete);
      this.idDelete = null;
    }
  }

  remove(id: any) {
    this.userS.supprimer(id)
      .subscribe(
        () => {
          this.messageSuccess('Suppression');
          this.store.dispatch(new UserActions.Delete(id));
        },
        (error) => this.messageError()
      );
  }

  getAccess(operation: string) {
    return this.userS.verifieRole(operation);
  }

  onUpdateUser(form: FormGroup) {
    if (form.valid) {
      const user = form.value;
      const depot = this.depots.find( d => d.nom === form.value.depots_id);
      user.depots_id = depot.id;

      this.userS.updateUser(user)
      .subscribe(
        (data) => {
          this.store.dispatch(new UserActions.Edit(data));
          this.messageSuccess('Modification');
        },
        (error) => {
          this.messageError();
          console.log('error ', error);
        }
      );
      this.closeModal();
    }
  }
}
