import { Router } from '@angular/router';
import { DechargementService } from './dechargement.service';
import { CommonService } from './../../../services/common/common.service';
import { IOption } from './../../../ui/interfaces/option';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BasePageComponent } from '../../base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { Content } from 'src/app/ui/interfaces/modal';
import * as DechActions from '../../../store/actions/dechargements.actions';

@Component({
  selector: 'app-dechargement',
  templateUrl: './dechargement.component.html',
  styleUrls: ['./dechargement.component.scss']
})
export class DechargementComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  loading = false;
  change: boolean;
  persons: IOption[];
  produits: IOption[];
  reservoirs: IOption[];
  numDech: number;
  depotId = '';
  
  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private comS: CommonService,
    private decharS: DechargementService,
    private router: Router
  ) { 
    super(store, httpSv, modal);
    this.pageData = {
      title: 'Identifier un tanker/barge',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'identification'
        }
      ]
    };
    this.change = false;
    this.produits = [];
    this.persons = [];
    this.reservoirs = [];
    this.numDech = 0;
  }

  ngOnInit() {
    super.ngOnInit();
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    this.depotId =  userInfo.depots_id.id;
    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(contenant => {
          if (contenant.type === 'CONTENEUR') {
            const c: IOption = {label: '', value: ''};
            c.label = contenant.nom.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
            c.value = contenant.id;
            this.reservoirs.push(c);
          }
        });
      }
    });

    this.store.select('produits').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(produit => {
          const c: IOption = {label: '', value: ''};
          c.label = produit.nature.toUpperCase();
          c.value = produit.id;
          this.produits.push(c);
        });
      }
    });

    this.store.select('persons').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(person => {
          if (person.type === 'FOURNISSEUR' || person.type === 'PROPRIETAIRE') {
            const c: IOption = {label: '', value: ''};
            c.label = person.nom.toUpperCase();
            c.value = person.id;
            this.persons.push(c);
          }
        });
      }
    });
    this.initForm();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.initForm();
    this.modal.open({
      body,
      header,
      footer,
      options: { height: 'auto', width: 500, closeButton: false, overlay: true, overlayClose: true }
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
    if (this.pageForm) {
      this.pageForm.reset();
    }
  }

   // init form
   initForm() {
    this.pageForm = this.fb.group({
      numero: [2000000],
      date_acostage: [new Date(), Validators.required],
      heure_acostage: [this.comS.hourString(new Date()), Validators.required],
      fournisseur: ['', Validators.required],
      conteneur: ['', Validators.required],
      origine: [''],
      produit: ['', Validators.required],
      quantite: [0, Validators.required],
      depot: [this.depotId]
    });

    // detect form changes
    this.pageForm.valueChanges.subscribe(() => {
      this.change = true;
    });
  }

  saveData(form: FormGroup) {
    if (form.valid) {
      const newData = form.value;
      newData.date_acostage = this.comS.dateString(newData.date_acostage);
      this.loading = true;
      this.decharS.create(newData)
      .subscribe(
        (data) => {
          if (data) {
            this.loading = false;
            this.messageSuccess('Création');
            this.store.dispatch(new DechActions.Add(data));
            setTimeout(() => {
              this.router.navigateByUrl('/vertical/reception-attendue');
            }, 4300);
          }
        },
        (error) => {
          this.loading = false;
          this.messageError();
        }
      );
    }
  }

}
