import { ILivraison } from './../../interfaces/models';
import * as LivraisonActions from '../actions/livraisons.actions';

export type Action = LivraisonActions.All;

// Reducer function
export function livraisonsReducer(data: ILivraison[] = [], action: Action) {
  switch (action.type) {
    case LivraisonActions.SET: {
      return action.data;
    }
    case LivraisonActions.EDIT: {
      data.forEach((depot: ILivraison, index) => {
        if (depot.id === action.data.id) {
          data[index] = action.data;
        }
      });

      return [...data];
    }
    case LivraisonActions.ADD: {
      return [action.data, ...data];
    }
    case LivraisonActions.DELETE: {
      const newData = data.filter((depot: ILivraison) => {
        return depot.id !== action.id;
      });
      
      return newData;
    }
    case LivraisonActions.OPEN_MODAL: {
      return true;
    }
    case LivraisonActions.CLOSE_MODAL: {
      return false;
    }
    default: {
      return data;
    }
  }
}
