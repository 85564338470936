import { IOption } from './../../../ui/interfaces/option';
import { TypePerson, IPerson } from './../../../interfaces/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { UserService } from '../user/user.service';
import { Content } from 'src/app/ui/interfaces/modal';
import * as PersonActions from '../../../store/actions/persons.actions';
import { PersonService } from './person.service';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent extends BasePageComponent implements OnInit, OnDestroy {

  persons: IPerson[];
  pageForm: FormGroup;
  loading = false;
  change: boolean;
  typePerson: IOption[];

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private userS: UserService,
    private personService: PersonService
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: 'Personne',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'Personne'
        }
      ]
    };
    this.typePersonne();

    this.persons = [];
    this.change = false;
    this.initData();
  }

  initData() {
    const data: IPerson = { id: null};
    this.persons.push(data);
  }

  typePersonne() {
    if (this.getAccess('creer')) {
      this.typePerson = [
        {
          label: 'Client',
          value: TypePerson.client
        }
      ];
    } else {
      this.typePerson = [
        {
          label: 'Client',
          value: TypePerson.client
        },
        {
          label: 'Fournisseur',
          value: TypePerson.fournisseur
        },
        {
          label: 'Propriétaire',
          value: TypePerson.proprietaire
        }
      ];
    }
  }
  ngOnInit() {
    super.ngOnInit();

    this.store.select('persons').subscribe(datas => {
      this.persons = [];
      this.initData();
      if (datas && datas.length) {
        this.persons.pop();
        datas.forEach(pers => {
          this.persons.push(pers);
        });
        !this.pageData.loaded ? this.setLoaded() : null;
      }
    });

    if (!this.persons.length) {
      this.persons = [];
      this.initData();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.initForm(row);
    this.modal.open({
      body,
      header,
      footer,
      options: null
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
    if (this.pageForm) {
      this.pageForm.reset();
    }
  }

  // init form
  initForm(data: any) {
    if (!data) {
      this.pageForm = this.fb.group({
        nom: ['', Validators.required],
        adresse: [''],
        type: [TypePerson.client, Validators.required],
        numero_reference: [0]
      });
    } else {
      this.pageForm = this.fb.group({
        nom: [data.nom, Validators.required],
        adresse: [data.adresse],
        type: [data.type, Validators.required],
        numero_reference: [data.numero_reference],
        id: [data.id]
      });
    }

     // detect form changes
    this.pageForm.valueChanges.subscribe(() => {
      this.change = true;
    });
  }

  getAccess(operation: string) {
    return this.userS.verifieRole(operation);
  }

  addPerson(form: FormGroup) {
    if (form.valid) {
      const newData: IPerson = form.value;
      this.loading = true;
      this.personService.create(newData)
        .subscribe(
          (data) => {
            if (data) {
              this.loading = false;
              this.messageSuccess('Création');
              this.store.dispatch(new PersonActions.Add(data));
            }
          },
          (error) => {
            this.loading = false;
            this.messageError();
          }
        );
  
      this.closeModal();
    }
  }

  onUpdate(form: FormGroup) {
    this.loading = true;
    if (form.valid) {
      const res = form.value;
      this.personService.updateDepot(res)
      .subscribe(
        (data) => {
          this.loading = false;
          this.store.dispatch(new PersonActions.Edit(data));
          this.messageSuccess('Modification');
        },
        (error) => {
          this.loading = false;
          this.messageError();
          console.log('error ', error);
        }
      );
      this.closeModal();
    }
  }

  onRemove() {
    this.closeModal();
    if (this.idDelete) {
      this.remove(this.idDelete);
      this.idDelete = null;
    }
  }

  remove(id: any) {
    this.loading = true;
    this.personService.supprimer(id)
      .subscribe(
        () => {
          this.loading = false;
          this.messageSuccess('Suppression');
          this.store.dispatch(new PersonActions.Delete(id));
        },
        (error) => {
          this.loading = false;
          this.messageError();
        }
      );
  }
}
