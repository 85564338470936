import { TransfertService } from './../transfert/transfert.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IOption } from 'src/app/ui/interfaces/option';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { CommonService } from 'src/app/services/common/common.service';
import { JaugeageService } from 'src/app/layout/vertical/jaugeage.service';
import * as TransfertActions from '../../../store/actions/transferts.actions';
import * as SjtActions from './../../../store/actions/stocks.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transfert-compteur',
  templateUrl: './transfert-compteur.component.html',
  styleUrls: ['./transfert-compteur.component.scss']
})
export class TransfertCompteurComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  loading = false;
  change: boolean;
  reservoir: any = '';
  produits: IOption[];
  reservoirs: IOption[];
  depotId = '';

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private comS: CommonService,
    private transfS: TransfertService,
    private jaugeS: JaugeageService,
    private router: Router
  ) { 
    super(store, httpSv, modal);
    this.pageData = {
      title: 'Transfert par compteur',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'transfer'
        }
      ]
    };
    this.change = false;
    this.produits = [];
    this.reservoirs = [];
  }

  ngOnInit() {
    super.ngOnInit();
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    this.depotId =  userInfo.depots_id.id;
    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(contenant => {
          if (contenant.type === 'RESERVOIR') {
            const c: IOption = {label: '', value: ''};
            c.label = contenant.nom.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
            c.value = contenant.id;
            this.reservoirs.push(c);
            this.reservoir = this.reservoirs[0].value;
          }
        });
      }
    });

    this.store.select('produits').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(produit => {
          const c: IOption = {label: '', value: ''};
          c.label = produit.nature.toUpperCase();
          c.value = produit.id;
          this.produits.push(c);
        });
      }
    });

    this.initForm();

  }

  // init form
  initForm() {
    this.pageForm = this.fb.group({
      date_transfert: [new Date(), Validators.required],
      heure_transfert: [this.comS.hourString(new Date()), Validators.required],
      tank_depart: [this.reservoir, Validators.required],
      tank_destination: [this.reservoir, Validators.required],
      la1: ['', Validators.required],
      l151: ['', Validators.required],
      la2: ['', Validators.required],
      l152: ['', Validators.required],
      type: ['COMPTEUR']
    });

    // detect form changes
    this.pageForm.valueChanges.subscribe(() => {
      this.change = true;
    });
  }

  saveData(form: FormGroup) {
    if (form.valid) {
      console.log('formulaire ', form.value);
      const newData = form.value;
      this.loading = true;
      this.transfS.compteur(newData)
      .subscribe(
        (data) => {
          this.loading = false;
          this.messageSuccess('Création');
          this.store.dispatch(new TransfertActions.Add(data));
          this.jaugeS.getData(this.jaugeS.url + '?ctrl=stock', 'stocks', 'setDataStore', SjtActions);
          setTimeout(() => {
            this.router.navigateByUrl('/vertical/transfert');
          }, 4300);
        },
        (error) => {
          this.loading = false;
          this.messageError();
        }
      );
    }
  }

}
