import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { IPageData } from '../../interfaces/page-data';
import { IAppState } from '../../interfaces/app-state';
import { HttpService } from '../../services/http/http.service';
import * as PageActions from '../../store/actions/page.actions';
import { Content } from 'src/app/ui/interfaces/modal';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';

@Component({
  selector: 'base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent implements OnInit, OnDestroy {
  pageData: IPageData;

  message = '';
  isNotValid = false;
  view = 'error';
  idDelete: any = null;

  constructor(
    public store: Store<IAppState>,
    public httpSv: HttpService,
    public modal?: TCModalService
  ) { }

  ngOnInit() {
    this.pageData ? this.store.dispatch(new PageActions.Set(this.pageData)) : null;
  }

  ngOnDestroy() {
    this.store.dispatch(new PageActions.Reset());
  }

  // get data
  // parameters:
  // * url - data url
  // * dataName - set data to 'dataName'
  // * callbackFnName run callback function with name 'callbackFnName'
  getData(url: string, dataName: string, callbackFnName?: string) {
    this.httpSv.getData(url).subscribe(
      data => {
        this[dataName] = data;
      },
      err => {
        console.log(err);
      },
      () => {
        (callbackFnName && typeof this[callbackFnName] === 'function') ? this[callbackFnName](this[dataName]) : null;
      }
    );
  }

   // open modal window
  openDeleteModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, id: any) {
    this.idDelete = id;

    this.modal.open({
      body,
      header,
      footer,
      options: null
    });
  }

  setLoaded(during: number = 0) {
    setTimeout(() => this.store.dispatch(new PageActions.Update({ loaded: true })), during);
  }

  timeOut() {
    setTimeout(() => {
      this.isNotValid = false;
    }, 4000);
  }

  messageSuccess(message: string) {
    this.view = 'success';
    this.isNotValid = true;
    this.message = message + ' effectuée avec succès !' ;
    this.timeOut();
  }

  messageError(message: string = 'Une erreur est survenue ! Veuillez recommencer !') {
    this.view = 'error';
    this.isNotValid = true;
    this.message = message ;
    this.timeOut();
  }
}
