import { Component, OnInit } from '@angular/core';
import { HttpService } from './services/http/http.service';
import { Store } from '@ngrx/store';
import { IAppState } from './interfaces/app-state';
import * as UsersActions from './store/actions/users.actions';
import * as DepotsActions from './store/actions/depots.actions';
import * as ProduitsActions from './store/actions/produits.actions';
import * as PersonsActions from './store/actions/persons.actions';
import * as ContenantActions from './store/actions/contenants.actions';
import * as JaugeageActions from './store/actions/jaugeages.actions';
import * as DechActions from './store/actions/dechargements.actions';
import * as SjtActions from './store/actions/stocks.actions';
import * as LivraisonActions from './store/actions/livraisons.actions';
import * as TransfertActions from './store/actions/transferts.actions';
import * as GestionActions from './store/actions/gestions.actions';


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  url = 'https://kuntualagestionpetro.bbhsarlu.com/gestiontankphp/';

  constructor(
    private store: Store<IAppState>,
    private httpSv: HttpService) {}

  ngOnInit() {
    this.getData(this.url + '?ctrl=user', 'users', 'setDataStore', UsersActions);
    this.getData(this.url + '?ctrl=depot', 'depots', 'setDataStore', DepotsActions);
    this.getData(this.url + '?ctrl=produit', 'produits', 'setDataStore', ProduitsActions);
    this.getData(this.url + '?ctrl=person', 'persons', 'setDataStore', PersonsActions);
    this.getData(this.url + '?ctrl=dechargement', 'dechargements', 'setDataStore', DechActions);
    this.getData(this.url + '?ctrl=contenant', 'contenants', 'setDataStore', ContenantActions);
    this.getData(this.url + '?ctrl=jaugeage', 'jaugeages', 'setDataStore', JaugeageActions);
    this.getData(this.url + '?ctrl=stock', 'stocks', 'setDataStore', SjtActions);
    this.getData(this.url + '?ctrl=sortie', 'livraisons', 'setDataStore', LivraisonActions);
    this.getData(this.url + '?ctrl=transfert', 'transferts', 'setDataStore', TransfertActions);
    this.getData(this.url + '?ctrl=stock&action=getStock', 'gestions', 'setDataStore', GestionActions);
  }

  getData(url: string, dataName: string, callbackFnName?: string, action?: any) {
    this.httpSv.getData(url).subscribe(
      data => {
        this[dataName] = data;
      },
      err => {
        console.log(err);
      },
      () => {
        (callbackFnName && typeof this[callbackFnName] === 'function') ? this[callbackFnName](this[dataName], action) : null;
      }
    );
  }

  setDataStore(datas: any[] = [], action: any) {
    this.store.dispatch(new action.Set(datas));
  }

}
