import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/http/data.service';

@Injectable({
  providedIn: 'root'
})
export class SignInService extends DataService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  login(user: any) {
    return this.post(user, '?ctrl=user&action=login')
    .pipe(
      map((data) => {
        if (data) {
          localStorage.setItem('myUser', JSON.stringify(data));
          return true;
        }
      })
    );
  }
}
