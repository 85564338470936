import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/http/data.service';

@Injectable({
  providedIn: 'root'
})
export class EditAccountService extends DataService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  postPseudo(user: any) {
    return this.update(user, '?ctrl=user&action=updatePseudo');
  }
}
