import { IProduit } from './../../interfaces/models';
import * as ProduitsActions from '../actions/produits.actions';

export type Action = ProduitsActions.All;

// Reducer function
export function produitsReducer(data: IProduit[] = [], action: Action) {
  switch (action.type) {
    case ProduitsActions.SET: {
      return action.data;
    }
    case ProduitsActions.EDIT: {
      data.forEach((d: IProduit, index) => {
        if (d.id === action.data.id) {
          data[index] = action.data;
        }
      });

      return [...data];
    }
    case ProduitsActions.ADD: {
      return [action.data, ...data];
    }
    case ProduitsActions.DELETE: {
      const newData = data.filter((d: IProduit) => {
        return d.id !== action.id;
      });

      console.log(newData);
      return newData;
    }
    case ProduitsActions.OPEN_MODAL: {
      return true;
    }
    case ProduitsActions.CLOSE_MODAL: {
      return false;
    }
    default: {
      return data;
    }
  }
}
