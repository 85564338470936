import { JaugeageService } from './../../../layout/vertical/jaugeage.service';
import { IJaugeage, IContenant, IDepot, Niveau } from './../../../interfaces/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { Content } from 'src/app/ui/interfaces/modal';
import { UserService } from '../user/user.service';
import * as JaugeagesActions from '../../../store/actions/jaugeages.actions';
import * as SjtActions from './../../../store/actions/stocks.actions';
import { IOption } from 'src/app/ui/interfaces/option';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-jaugeage',
  templateUrl: './jaugeage.component.html',
  styleUrls: ['./jaugeage.component.scss']
})
export class JaugeageComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  loading = false;
  change: boolean;
  jaugeages: IJaugeage[];
  typeJauge: IOption[];
  reservoirs: IOption[];
  depot: IDepot;
  jaugeage: IJaugeage;
  date: Date;
  tuyau = false;
  dateDebut: Date;
  dateFin: Date;
  opt = { year: 'numeric', month: 'long', day: 'numeric' };
  userInfo = null;
  depotId = null;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private userS: UserService,
    private fb: FormBuilder,
    private jaugeS: JaugeageService,
    private comS: CommonService
  ) { 
    super(store, httpSv, modal);
    this.pageData = {
      title: 'Historique des Jaugeages',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'Jaugeage'
        }
      ]
    };

    this.jaugeages = [];
    this.change = false;
    this.reservoirs = [];
    this.depot = {};
    this.initData();
    this.jaugeage = {};
    this.dateDebut = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.dateFin = new Date(new Date().getFullYear(), new Date().getMonth(), 2);
  }

  initData() {
    const data: IJaugeage = {date: null};
    this.jaugeages.push(data);
  }

  ngOnInit() {
    super.ngOnInit();
    this.userInfo = JSON.parse(localStorage.getItem('myUser'));
    this.depotId =  this.userInfo.depots_id.id;
    this.depot = this.userInfo.depots_id;
    

    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(contenant => {
          if (this.userInfo.niveau === Niveau.admin || this.userInfo.niveau === Niveau.superAdministrateur) {
            const c: IOption = {label: '', value: ''};
            c.label = contenant.code.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
            c.value = contenant.id;
            this.reservoirs.push(c);
          } else {
            if (contenant.depots_id.id === this.depotId && contenant.type === 'RESERVOIR') {
              const c: IOption = {label: '', value: ''};
              c.label = contenant.code.toUpperCase()
                        + ' - Capacité: ' + contenant.capacite + 'M3'
                        + ' - [' + contenant.produits_id.code.toUpperCase() + ']';
              c.value = contenant.id;
              this.reservoirs.push(c);
            }
          }
        });
      }
    });

    this.afficher();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: IJaugeage = null) {
    if (row.type === 'TUYAUTERIE') {
      this.tuyau = true;
      this.initForm(row);
      this.modal.open({
        body,
        header,
        footer,
        options: { height: 'auto', width: 650, closeButton: false, overlay: true, overlayClose: true }
      });
    } else {
      this.initForm(row);
      this.tuyau = false;
      this.modal.open({
        body,
        header,
        footer,
        options: { height: 'auto', width: 650, closeButton: false, overlay: true, overlayClose: true }
      });
    }
  }

  // close modal window
  closeModal() {
    this.modal.close();
    if (this.pageForm) {
      this.pageForm.reset();
    }
  }

   // init form
   initForm(data: any) {
    this.jaugeage = data;
  }

  getAccess(operation: string) {
    return this.userS.verifieRole(operation);
  }

  onRemove() {
    this.closeModal();
    if (this.idDelete) {
      this.remove(this.idDelete);
      this.idDelete = null;
    }
  }

  remove(id: any) {
    this.loading = true;
    this.jaugeS.supprimer(id)
    .subscribe(
      () => {
        this.loading = false;
        this.messageSuccess('Suppression');
        this.store.dispatch(new JaugeagesActions.Delete(id));
        this.jaugeS.getData(this.jaugeS.url + '?ctrl=stock', 'stocks', 'setDataStore', SjtActions);
      },
      (error) => {
        this.loading = false;
        this.messageError();
      }
    );
  }

  onUpdate() {
    this.loading = true;
    const newData = this.jaugeage;
    newData.d15 = this.jaugeS.densite15(newData.da, newData.ta);
    newData.coef = this.jaugeS.coef(newData.d15, newData.tm);

    if (this.jaugeage.idreservoir) {
      this.jaugeS.updateJauge(newData)
      .subscribe(
        (data) => {
          this.loading = false;
          this.store.dispatch(new JaugeagesActions.Edit(data));
          this.messageSuccess('Modification');
          this.jaugeS.getData(this.jaugeS.url + '?ctrl=stock', 'stocks', 'setDataStore', SjtActions);
        },
        (error) => {
          this.loading = false;
          this.messageError();
          console.log('error ', error);
        }
      );
      this.closeModal();
    }
  }

  afficher() {
    this.store.select('jaugeages').subscribe(datas => {
      this.jaugeages = [];
      this.initData();
      if (datas && datas.length) {
        this.jaugeages.pop();
        datas.forEach(jauge => {
          if (jauge.iddepot === this.depotId || this.userInfo.Niveau === 'ADMIN_DEPOT' || this.userInfo.niveau === 'SUPER_ADMIN') {
            if ((new Date(jauge.date ) >= this.dateDebut) && (new Date(jauge.date ) <= this.dateFin)) {
              this.jaugeages.push(jauge);
            }
          }
        });
      }
    });

    if (!this.jaugeages.length) {
      this.jaugeages = [];
      this.initData();
    }
  }
}
