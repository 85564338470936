import { AppError } from 'src/app/services/errors/app-error';
import { InternalServerError } from 'src/app/services/errors/InternalServerError';
import { NotFoundError } from 'src/app/services/errors/not-found-error';
import { ForbidenError } from 'src/app/services/errors/forbiden-error';
import { UnauthorizedError } from 'src/app/services/errors/unauthorized-error';
import { SignInService } from './sign-in.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'page-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class PageSignInComponent implements OnInit {
  loginForm: FormGroup;
  loadingLoginForm: boolean;
  message: string;
  isnotValid: boolean;

  constructor(
    private fb: FormBuilder,
    private signService: SignInService,
    private route: Router) {

    this.loadingLoginForm = false;
    this.message = '';
    this.isnotValid = false;
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onLogin(form: FormGroup) {
    if (form.valid) {
      this.loadingLoginForm = true;
      this.signService.login(form.value)
      .subscribe(
        (resp) => {
          if (resp) {
            this.loadingLoginForm = false;
            this.route.navigateByUrl('/vertical/default-dashboard');
          }
        },
        (error: AppError) => {
          console.log('error', error);
          this.loadingLoginForm = false;
          this.isnotValid = true;

          if (error instanceof UnauthorizedError) {
            this.message = 'Mot de passe incorrect !';
          } else {
            if (error instanceof ForbidenError) {
              this.message = 'Votre compte a été bloqué !';
            } else {
              if (error instanceof NotFoundError) {
                this.message = 'Utilisateur non trouvé !';
              } else {
                if (error instanceof InternalServerError) {
                  this.route.navigateByUrl('page-500');
                } else {
                  this.message = 'Une erreur inconnue!';
                }
              }
            }
          }
        }
      );
    }
  }
}
