import { IDepot, Niveau } from './../../../interfaces/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { Content } from 'src/app/ui/interfaces/modal';
import { ITransfert } from 'src/app/interfaces/models';
import { TransfertService } from './transfert.service';
import * as TransfertActions from '../../../store/actions/transferts.actions';
import * as SjtActions from './../../../store/actions/stocks.actions';
import { JaugeageService } from 'src/app/layout/vertical/jaugeage.service';


@Component({
  selector: 'app-transfert',
  templateUrl: './transfert.component.html',
  styleUrls: ['./transfert.component.scss']
})
export class TransfertComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  change: boolean;
  loading = false;
  dateDebut: Date;
  dateFin: Date;
  transferts: ITransfert[];
  depotUser: any;
  roleUser: any;
  opt = { year: 'numeric', month: 'long', day: 'numeric' };

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private transfertS: TransfertService,
    private jaugeS: JaugeageService
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: 'Transfert',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'transfert'
        }
      ]
    };
    this.change = false;
    this.dateDebut = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.dateFin = new Date();

    this.depotUser = null;
    this.roleUser = '';
    this.afficher();
  }

  ngOnInit() {
    super.ngOnInit();
    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    if (userInfo.depots_id) {
      this.depotUser = userInfo.depots_id.id;
    }
    this.roleUser = userInfo.niveau;

    this.transferts = [];
    this.initData();

    this.afficher();
  }

  initData() {
    const data: ITransfert = {
      numero: null
    };

    this.transferts.push(data);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.modal.open({
      body,
      header,
      footer,
      options : { height: 'auto', width: 600, closeButton: false, overlay: true, overlayClose: true }
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
  }

  afficher() {
    this.store.select('transferts')
    .subscribe(
      (datas) => {
        this.transferts = [];
        this.initData();
        const total: ITransfert = {
          heure: 'TOTAL',
          total_ambiant: 0,
          total15: 0
        };
        if (datas && datas.length) {
          this.transferts.pop();
          datas.forEach(transf => {
            if (this.roleUser === Niveau.superAdministrateur || this.roleUser === Niveau.admin) {
              if (((new Date(transf.date ) >= this.dateDebut) && (new Date(transf.date ) <= this.dateFin))) {
                  this.transferts.push(transf);
                  total.total_ambiant += transf.total_ambiant;
                  total.total15 += transf.total15;

                }
            } else {
              if (((new Date(transf.date ) >= this.dateDebut)
              && (new Date(transf.date ) <= this.dateFin))
              && transf.id_depot === this.depotUser) {
                this.transferts.push(transf);
                total.total_ambiant += transf.total_ambiant;
                total.total15 += transf.total15;
              }
            }
          });

          if (!this.transferts.length) {
            this.transferts = [];
            this.initData();
          } else {
            this.transferts.push(total);
          }
        }
      }
    );
  }

  onRemove() {
    this.closeModal();
    if (this.idDelete) {
      this.remove(this.idDelete);
      this.idDelete = null;
    }
  }

  remove(id: any) {
    this.loading = true;
    this.transfertS.supprimer(id)
    .subscribe(
      () => {
        this.loading = false;
        this.messageSuccess('Suppression');
        this.store.dispatch(new TransfertActions.Delete(id));
        this.jaugeS.getData(this.jaugeS.url + '?ctrl=stock', 'stocks', 'setDataStore', SjtActions);
      },
      (error) => {
        this.loading = false;
        this.messageError();
      }
    );
  }
}
