import { IOption } from './../../../ui/interfaces/option';
import { IContenant, IPerson, IDepot, IProduit, TypeContenant } from './../../../interfaces/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../user/user.service';
import { Content } from 'src/app/ui/interfaces/modal';
import * as ContenantActions from '../../../store/actions/contenants.actions';
import { ReservoirService } from './reservoir.service';

@Component({
  selector: 'app-reservoir',
  templateUrl: './reservoir.component.html',
  styleUrls: ['./reservoir.component.scss']
})
export class ReservoirComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  loading = false;
  change: boolean;
  contenants: IContenant[];
  persons: IPerson[];
  depots: IDepot[];
  produits: IProduit[];
  typeContenant: IOption[];

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private userS: UserService,
    private reservoirService: ReservoirService
  ) {
    super(store, httpSv, modal);

    this.pageData = {
      title: 'Réservoir',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'Réservoir'
        }
      ]
    };

    this.typeContenant = [
      {
        label: 'Conteneur',
        value: TypeContenant.conteneur
      },
      {
        label: 'Réservoir (tank)',
        value: TypeContenant.reservoir
      },
      {
        label: 'Tuyauterie',
        value: TypeContenant.tuyauterie
      },
      {
        label: 'Tanker',
        value: TypeContenant.tanker
      },
      {
        label: 'Barge',
        value: TypeContenant.barge
      },
      {
        label: 'Camion Citerne',
        value: TypeContenant.camion
      },
      {
        label: 'Wagon Citerne',
        value: TypeContenant.wagon
      }
    ];
    this.persons = [];
    this.contenants = [];
    this.produits = [];
    this.depots = [];
    this.change = false;
    this.initData();
  }

  initData() {
    const data: IContenant = { id: null};
    this.contenants.push(data);
  }

  ngOnInit() {
    super.ngOnInit();

    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        if (!this.contenants[0].id) {
          this.contenants.pop();
        }
        this.contenants = datas;
        !this.pageData.loaded ? this.setLoaded() : null;
      }
    });

    this.store.select('depots').subscribe(datas => {
      if (datas && datas.length) {
        this.depots = datas;
        !this.pageData.loaded ? this.setLoaded() : null;
      }
    });

    this.store.select('persons').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(person => {
          if (person.type === 'FOURNISSEUR') {
            this.persons.push(person);
          }
        });
      }
    });

    this.store.select('produits').subscribe(datas => {
      if (datas && datas.length) {
        this.produits = datas;
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.initForm(row);
    this.modal.open({
      body,
      header,
      footer,
      options: null
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
    if (this.pageForm) {
      this.pageForm.reset();
    }
  }

  // init form
  initForm(data: IContenant) {
    if (!data) {
      this.pageForm = this.fb.group({
        nom: ['', Validators.required],
        code: [''],
        type: [TypeContenant.reservoir, Validators.required],
        capacite: [0],
        persons_id: [],
        produits_id: [, Validators.required],
        depots_id: [],
        debut: [0],
        fin: [0]
      });
    } else {
      if (data.depots_id === null) {
        data.depots_id = {};
      }

      if (data.persons_id === null) {
        data.persons_id = {};
      }
      this.pageForm = this.fb.group({
        nom: [data.nom, Validators.required],
        code: [data.code],
        type: [data.type, Validators.required],
        capacite: [data.capacite],
        persons_id: [data.persons_id.nom],
        produits_id: [data.produits_id.nature],
        depots_id: [data.depots_id.nom],
        id: [data.id]
      });
    }

     // detect form changes
    this.pageForm.valueChanges.subscribe(() => {
      this.change = true;
    });
  }

  getAccess(operation: string) {
    return this.userS.verifieRole(operation);
  }

  onCreate(form: FormGroup) {
    if (form.valid) {
      const newData = form.value;
      const depot = this.depots.find( d => d.nom === form.value.depots_id);
      const produit = this.produits.find( d => d.nature === form.value.produits_id);
      const person = this.persons.find( d => d.nom === form.value.persons_id);

      newData.capacite = +newData.capacite ;
      newData.longueur = +newData.longueur;
      newData.depots_id ?  newData.depots_id = depot.id : null;
      newData.produits_id ? newData.produits_id = produit.id : null;
      newData.persons_id ? newData.persons_id = person.id : null;
      console.log('reser', newData);

      this.loading = true;
      this.reservoirService.create(newData)
      .subscribe(
        (data) => {
          if (data) {
            this.loading = false;
            this.messageSuccess('Création');
            this.store.dispatch(new ContenantActions.Add(data));
          }
        },
        (error) => {
          this.loading = false;
          this.messageError();
        }
      );
    }
  }

  onUpdate(form: FormGroup) {
    this.loading = true;
    if (form.valid) {
      const newData = form.value;
      const depot = this.depots.find( d => d.nom === form.value.depots_id);
      const produit = this.produits.find( d => d.nature === form.value.produits_id);
      const person = this.persons.find( d => d.nom === form.value.persons_id);

      newData.depots_id ?  newData.depots_id = depot.id : null;
      newData.produits_id ? newData.produits_id = produit.id : null;
      newData.persons_id ? newData.persons_id = person.id : null;
      console.log('upd', newData);
      this.reservoirService.updateContenant(newData)
      .subscribe(
        (data) => {
          this.loading = false;
          this.store.dispatch(new ContenantActions.Edit(data));
          this.messageSuccess('Modification');
        },
        (error) => {
          this.loading = false;
          this.messageError();
          console.log('error ', error);
        }
      );
      this.closeModal();
    }
  }

  onRemove() {
    this.closeModal();
    if (this.idDelete) {
      this.remove(this.idDelete);
      this.idDelete = null;
    }
  }

  remove(id: any) {
    this.loading = true;
    this.reservoirService.supprimer(id)
      .subscribe(
        () => {
          this.loading = false;
          this.messageSuccess('Suppression');
          this.store.dispatch(new ContenantActions.Delete(id));
        },
        (error) => {
          this.loading = false;
          this.messageError();
        }
      );
  }

}
