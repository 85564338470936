import { IPerson } from './../../interfaces/models';
import * as PersonsActions from '../actions/persons.actions';

export type Action = PersonsActions.All;

// Reducer function
export function personsReducer(data: IPerson[] = [], action: Action) {
  switch (action.type) {
    case PersonsActions.SET: {
      return action.data;
    }
    case PersonsActions.EDIT: {
      data.forEach((d: IPerson, index) => {
        if (d.id === action.data.id) {
          data[index] = action.data;
        }
      });

      return [...data];
    }
    case PersonsActions.ADD: {
      return [action.data, ...data];
    }
    case PersonsActions.DELETE: {
      const newData = data.filter((d: IPerson) => {
        return d.id !== action.id;
      });

      console.log(newData);
      return newData;
    }
    case PersonsActions.OPEN_MODAL: {
      return true;
    }
    case PersonsActions.CLOSE_MODAL: {
      return false;
    }
    default: {
      return data;
    }
  }
}
