import { JaugeageService } from './../../../layout/vertical/jaugeage.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/interfaces/app-state';
import { HttpService } from 'src/app/services/http/http.service';
import { TCModalService } from 'src/app/ui/services/modal/modal.service';
import { Content } from 'src/app/ui/interfaces/modal';
import * as JaugeagesActions from '../../../store/actions/jaugeages.actions';
import { IOption } from 'src/app/ui/interfaces/option';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import * as SjtActions from './../../../store/actions/stocks.actions';
import * as DechActions from './../../../store/actions/dechargements.actions';
import { ReceptionService } from '../reception/reception.service';

@Component({
  selector: 'app-jaugeage-reception',
  templateUrl: './jaugeage-reception.component.html',
  styleUrls: ['./jaugeage-reception.component.scss']
})
export class JaugeageReceptionComponent extends BasePageComponent implements OnInit, OnDestroy {

  pageForm: FormGroup;
  loading = false;
  change: boolean;
  numero: number;
  reservoirs: IOption[];
  reservoir: any = '';

  totalAmbiant = 0;
  total15 = 0;
  tmvApres = 0;
  tmvAvant = 0;
  tmvTotal = 0;

  constructor(
    store: Store<IAppState>,
    httpSv: HttpService,
    modal: TCModalService,
    private fb: FormBuilder,
    private comS: CommonService,
    private jaugeS: JaugeageService,
    private receptionS: ReceptionService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(store, httpSv, modal);
    this.pageData = {
      title: 'Jaugeages de Reception',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Situation journalière',
          route: 'default-dashboard'
        },
        {
          title: 'Jaugeage Reception'
        }
      ]
    };

    this.change = false;
    this.numero = 0;
    this.reservoirs = [];
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.params.subscribe(
      (params: Params) => {
        this.numero = +params.numero;
      }
    );

    const userInfo = JSON.parse(localStorage.getItem('myUser'));
    const depotId =  userInfo.depots_id.id;
    this.store.select('contenants').subscribe(datas => {
      if (datas && datas.length) {
        datas.forEach(contenant => {
          if (userInfo.niveau === 'ADMIN_DEPOT' || userInfo.niveau === 'SUPER_ADMIN' && contenant.type === 'RESERVOIR') {
            const c: IOption = {label: '', value: ''};
            c.label = contenant.code.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
            c.value = contenant.id;
            this.reservoirs.push(c);
          } else {
            if (contenant.depots_id.id === depotId && contenant.type === 'RESERVOIR') {
              const c: IOption = {label: '', value: ''};
              c.label = contenant.code.toUpperCase() + ' [' + contenant.produits_id.nature.toUpperCase() + ']';
              c.value = contenant.id;
              this.reservoirs.push(c);
              this.reservoir = this.reservoirs[0].value;
            }
          }
        });
      }
    });
    this.initForm();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  // open modal window
  openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, row: any = null) {
    this.modal.open({
      body,
      header,
      footer,
      options: { height: 'auto', width: 650, closeButton: false, overlay: true, overlayClose: true }
    });
  }

  // close modal window
  closeModal() {
    this.modal.close();
    if (this.pageForm) {
      this.pageForm.reset();
    }
  }

   // init form
   initForm() {
    this.pageForm = this.fb.group({
      numero: [this.numero],
      reservoir: [this.reservoir, Validators.required],
      dateAvant: [new Date(), Validators.required],
      heureAvant: [this.comS.hourString(new Date()), Validators.required],
      hpAvant: [0, Validators.required],
      heAvant: [0, Validators.required],
      tmAvant: [27, Validators.required],
      daAvant: [0, Validators.required],
      taAvant: [25, Validators.required],
      d15Avant: [0],
      lpAvant: [0],
      leauAvant: [0],
      laAvant: [0],
      l15Avant: [0],
      tmvAvant: [0],
      coefAvant: [0],

      dateApres: [new Date(), Validators.required],
      heureApres: [this.comS.hourString(new Date()), Validators.required],
      hpApres: [0, Validators.required],
      heApres: [0, Validators.required],
      tmApres: [27, Validators.required],
      daApres: [0, Validators.required],
      taApres: [25, Validators.required],
      d15Apres: [0],
      lpApres: [0],
      leauApres: [0],
      laApres: [0],
      l15Apres: [0],
      tmvApres: [0],
      coefApres: [0],
      type: ['RECEPTION'],
      totalAmbiant: [0],
      tmvTotal: [0],
      total15: [0]
    });

    // detect form changes
    this.pageForm.valueChanges.subscribe((form) => {
      this.change = true;
    });
  }

  saveData(form: FormGroup) {
    if (form.valid) {
      this.loading = true;
      const newData = form.value;
      newData.d15Avant = this.jaugeS.densite15(newData.daAvant, newData.taAvant);
      newData.coefAvant = this.jaugeS.coef(newData.d15Avant, newData.tmAvant);
      newData.d15Apres = this.jaugeS.densite15(newData.daApres, newData.taApres);
      newData.coefApres = this.jaugeS.coef(newData.d15Apres, newData.tmApres);

      this.receptionS.create(newData)
      .subscribe(
        (data) => {
          this.loading = false;
          this.messageSuccess('Création');
          this.store.dispatch(new JaugeagesActions.Add(data.avant));
          this.store.dispatch(new JaugeagesActions.Add(data.apres));
          this.jaugeS.getData(this.jaugeS.url + '?ctrl=stock', 'stocks', 'setDataStore', SjtActions);
          this.jaugeS.getData(this.jaugeS.url + '?ctrl=dechargement', 'dechargements', 'setDataStore', DechActions);
          setTimeout(() => {
            this.router.navigateByUrl('/vertical/reception-attendue');
          }, 4300);
        },
        (error) => {
          this.loading = false;
          this.messageError();
        }
      );
    }
  }

  calculer() {
    this.loading = true;
    const newData = this.pageForm.value;
    newData.d15Avant = this.jaugeS.densite15(newData.daAvant, newData.taAvant);
    newData.coefAvant = this.jaugeS.coef(newData.d15Avant, newData.tmAvant);
    newData.d15Apres = this.jaugeS.densite15(newData.daApres, newData.taApres);
    newData.coefApres = this.jaugeS.coef(newData.d15Apres, newData.tmApres);

    console.log(newData);
    this.receptionS.calculer(newData).subscribe(
      (resp) => {
        this.loading = false;
        console.log('resp', resp);
        this.total15 = resp.total15 + 0;
        this.totalAmbiant = resp.totalAmbiant + 0;
        this.tmvAvant = resp.tmvAvant + 0;
        this.tmvApres = resp.tmvApres + 0;
        this.tmvTotal = this.tmvApres - this.tmvAvant;
      }
    );
  }
}
