import * as TransfertActions from './../actions/transferts.actions';
import { ITransfert } from './../../interfaces/models';

export type Action = TransfertActions.All;

// Reducer function
export function transfertsReducer(data: ITransfert[] = [], action: Action) {
  switch (action.type) {
    case TransfertActions.SET: {
      return action.data;
    }
    case TransfertActions.EDIT: {
      data.forEach((c: ITransfert, index) => {
        if (c.id === action.data.id) {
          data[index] = action.data;
        }
      });

      return [...data];
    }
    case TransfertActions.ADD: {
      return [action.data, ...data];
    }
    case TransfertActions.DELETE: {
      const newData = data.filter((c: ITransfert) => {
        return c.id !== action.id;
      });
      return newData;
    }
    case TransfertActions.OPEN_MODAL: {
      return true;
    }
    case TransfertActions.CLOSE_MODAL: {
      return false;
    }
    default: {
      return data;
    }
  }
}
