import { IGestion } from '../../interfaces/models';
import { Action } from '@ngrx/store';

export const SET = '[Gestion] Set';
export const ADD = '[Gestion] Add';
export const EDIT = '[Gestion] Edit';
export const DELETE = '[Gestion] Delete';
export const OPEN_MODAL = '[Gestion] Open modal';
export const CLOSE_MODAL = '[Gestion] Close modal';

export class Set implements Action {
  readonly type = SET;

  constructor(public data: IGestion[]) {}
}

export class Add implements Action {
  readonly type = ADD;

  constructor(public data: IGestion) {}
}

export class Edit implements Action {
  readonly type = EDIT;

  constructor(public data: IGestion) {}
}

export class Delete implements Action {
  readonly type = DELETE;

  constructor(public id: any) {}
}

export class OpenModal implements Action {
  readonly type = OPEN_MODAL;
}

export class CloseModal implements Action {
  readonly type = CLOSE_MODAL;
}

export type All = Set | Add | Edit | Delete | OpenModal | CloseModal;
